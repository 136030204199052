// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

export default (tmp, utc=false, dateOnly=false) => {

    if (!tmp || tmp === "")
        return "";

    const date = new Date(Date.parse(tmp));

    // eslint-disable-next-line
    if (date == 'Invalid Date')
        return tmp;

    const today = new Date();

    // Today! Return time
    if (!dateOnly && date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear())
        return date.toLocaleString('en-US', {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        });

    // Not today - return date (UTC)
    else if (utc) {
        const dateStr = date.toUTCString();
        return dateStr.substring(4,dateStr.length-13);
    }
    
    // Not today - return date (Locale)
    else return date.toLocaleString('en-US', {
            day: "numeric",
            month: "short",
            year: "numeric"
        });
}