// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

export default (tmp) => {
    if (!tmp || tmp === "")
        return "";

    let rv = "";
    for (const c of tmp.split(" "))
        rv += c[0].toUpperCase() + c.slice(1) + " ";

    return rv.trim();
}