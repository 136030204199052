// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

import '../styles/contextmenu.css';

const millis = () => (new Date()).getTime();

export default class ContextMenu extends React.Component {

    state = {
        opened: false,
        left: 0,
        top: 0,
        time: millis()
    }

    closeTimeout = null

    componentDidMount = () => {
        if (this.props.load)
            this.props.load(this.open, this.close);
    }

    componentWillUnmount = () => {
        this.close();
    }

    close = () => {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
            document.removeEventListener("click", this.close);
            document.removeEventListener("contextmenu", this.close);
        }

        if (millis() < this.state.time + 100) 
            return;

        this.setState({ opened: false });
    }

    open = (e, data) => {
        e.stopPropagation();
        e.preventDefault();

        let state = {
            opened: true,
            left: undefined,
            right: undefined,
            top: undefined,
            bottom: undefined,
            time: millis(),
            data,
            x: e.pageX,
            y: e.pageY
        };

        if (e.pageY > window.innerHeight/2 && e.pageY > window.innerHeight - 300)
            state.bottom = window.innerHeight - e.pageY;

        else state.top = e.pageY;

        if (e.pageX > window.innerWidth/2 && e.pageX > window.innerWidth - 200)
            state.right = window.innerWidth - e.pageX;

        else state.left = e.pageX;

        this.setState(state);

        // Set event listeners
        this.closeTimeout = setTimeout(() => {
            document.addEventListener("click", this.close);
            document.addEventListener("contextmenu", this.close);
        }, 250);
    }

    render = () => {

        return (<div
            className={"contextmenu-menu" + (this.state.opened ? " contextmenu-menu-opened" : "")}
            style={{
                left: this.state.left,
                right: this.state.right,
                top: this.state.top,
                bottom: this.state.bottom
            }}
        >

            {this.props.options && (typeof this.props.options === "function" ? this.props.options(this.state.data) : this.props.options).map((opt,idx) => <div
                key={idx}
                className="contextmenu-option"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // e.persist();
                    opt.action(this.state.data, this.state.x, this.state.y);
                    this.close();
                }}
            >
                {typeof opt.label === "function" ? opt.label(this.state.data) : opt.label}
            </div>)}

        </div>);

    }

};