// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import api from '../../api';
import '../../styles/sidebar.css';

const ACCESS_LEVELS = {
    'member': 0,
    'admin': 1,
    'owner': 2
}

class Sidebar extends Component {

    p = () => this.props.product ? '?product=' + this.props.product._id : ""

    link = (title, href, icon, access=null, team=false) => {
        const login = this.props.login;
        if (access && (!login || ACCESS_LEVELS[access] > ACCESS_LEVELS[login.access_level]))
            return <></>;
        if (team && !login.team)
            return <></>;

        return <div
            className="sidebar-a"
            onClick={() => this.props.history.push(href + this.p())}
            title={title}
            style={{
                backgroundImage: "url('/images/icons/" + icon + ".png')"
            }}
        >{title}</div>;
    }

    logout = async () => {
        if (!this.props.login || !this.props.login._id) return;
        await this.props.loading();
        await api.post('/u/memberships/' + this.props.login._id + '/logout');
        window.location.href = '/oauth/login' + this.p();
        this.props.loaded();
    }

    render = () => {
        if (!this.props.login) return <></>;
        return <div className="sidebar">
            <h1>Manage Account</h1>

            {this.link("My Profile", "/manage/profile", "profile")}
            {this.link("Team Members", "/manage/members", "members", "admin", true)}
            {this.link("Billing & Usage", "/manage/billing", "billing", "admin")}
            {this.link("Payment Methods", "/manage/payment", "payment-methods", "admin")}
            {this.link(this.props.login.team ? "Delete Team" : "Delete Account", "/manage/delete", "delete", "owner")}

            <div
                className="sidebar-a"
                onClick={this.logout}
                style={{ backgroundImage: "url('/images/icons/logout.png')" }}
            >Logout</div>

        </div>;
    }
}

export default withRouter(Sidebar);