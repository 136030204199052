// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import api from '../api';

const ids = [];
export default class Gravatar extends Component {
    state = {
        opacity: 0,
        idx: -1
    }
    componentDidMount = () => this.loadId()
    componentDidUpdate = () => this.loadId()

    loadId = () => {
        if (!this.props.user || !this.props.user._id || this.state.idx >= 0) return;
        let idx = ids.indexOf(this.props.user._id);
        if (idx < 0) {
            idx = ids.length;
            ids.push(this.props.user._id);
        }
        this.setState({ idx });
    }

    render = () => {
        if (!this.props.user) return <></>;
        const { _id, name } = this.props.user;

        let initials = "";
        if (name) {
            for (const cmp of name.split(" ")) {
                if (cmp.length === 0) continue;
                initials += cmp[0];
                if (initials.length === 2) break;
            }
        }

        return <div
            className={"avatar palette-color-" + String(this.state.idx % 8) + (this.props.className ? " " + this.props.className : "")}
            style={this.props.style ? this.props.style : {}}
        >
            {initials}
            <img
                // src={avatar ? avatar : "https://www.gravatar.com/avatar/" + hashes[email] + "?d=blank"}
                src={api.apiUrl + "/users/" + _id + "/avatar"}
                alt=""
                style={{ opacity: this.state.opacity }}
                onLoad={() => this.setState({ opacity: 1 })}
            />
        </div>;
    }
}