// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

export default () => {
    let accounts = {};
    try {
        accounts = JSON.parse(localStorage.getItem('ACCOUNTS'));
    }
    catch (error) {
        console.log("Could not load accounts cache.");
        console.log(error);
    }
    return accounts;
}