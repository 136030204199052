// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

export default class CreateTeam extends Component {

    componentDidMount = () => {
        // This is just a dummy redirect page.
        this.props.history.push('/oauth/login' + this.props.location.search + '&next=new-subscription');
    }

    render = () => (<></>);

};