// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import '../styles/editable.css';

export default class Editable extends Component {
    state = {
        value: "",
        editing: false
    }

    edit = () => {
        this.setState({ value: this.props.value, editing: true });
        setImmediate(() => {
            const input = this.refs.textbox;
            if (input) {
                input.focus();
                input.select();
            }
        });
    }

    save = async () => {
        const value = this.state.value;
        await this.setState({ value: "", editing: false });
        this.props.setValue(value);
    }

    render = () => {
        return <div
            className={"editable " + (this.props.className ? this.props.className : "")}
        >
            {this.state.editing ? <>
                <input
                    type="text"
                    ref="textbox"
                    value={this.state.value}
                    className={this.props.inputClass}
                    placeholder={this.props.placeholder ? this.props.placeholder : "Enter value..."}
                    onChange={(e) => this.setState({ value: e.target.value })}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter')
                            this.save();
                    }}
                />
                <img 
                    alt="Save"
                    src="/images/icons/confirm.png"
                    title="Click or hit Enter to save"
                    className="editable-button-save"
                    onClick={this.save}
                />
            </> : <>
                {this.props.value && this.props.value.length ? <div
                    className={"editable-value " + (this.props.valueClass ? this.props.valueClass : "")}
                >{this.props.value}</div> : this.props.placeholder && this.props.placeholder.length ? <div
                    className={"editable-placeholder " + (this.props.palceholderClass ? this.props.palceholderClass : "")}
                >{this.props.placeholder}</div> : ""}
                <img 
                    alt="Edit"
                    src="/images/icons/edit.png"
                    title="Click here to edit"
                    className="editable-button-edit"
                    onClick={this.edit}
                />
            </>}
        </div>;
    }
}