// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import Modal from './Modal';

export default class ConfirmModal extends Modal {

    renderContent = () => {

        const d = this.props.data ? this.props.data : {};

        return (<div className="modal-content modal-center">
            
            <div style={{
                height: 160
            }} />

            <h1>{d.title ? d.title : "Are you sure?"}</h1>

            <p className="modal-caution-message">{d.message}</p>

            <div className="modal-caution-buttons">
                <div
                    className="modal-caution-decline"
                    onClick={() => this.props.modal("")}
                >← No, take me back.</div>

                <div 
                    className="modal-caution-confirm"
                    onClick={() => {
                        d.action();
                        this.props.modal("");
                    }}
                >Confirm</div>
            </div>
            
        </div>);
    }

};