// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import getAccounts from "./getAccounts";

export default (productId, userEmail) => {
    let accountsCache = getAccounts();
    if (!accountsCache) accountsCache = {};

    let accounts = accountsCache[productId] && accountsCache[productId].length ? accountsCache[productId] : [];

    const idx = accounts.findIndex(a => a.email === userEmail);
    if (idx < 0) return;
    accounts.splice(idx, 1);
    accountsCache[productId] = accounts;

    // Save to local state
    localStorage.setItem('ACCOUNTS', JSON.stringify(accountsCache));
}