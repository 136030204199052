// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Page roots
import OAuth from './pages/OAuth';
import Manage from './pages/Manage';

// Modals
import ConfirmModal from './components/Modals/Confirm';
import InviteModal from './components/Modals/Invite';
import AddCardModal from './components/Modals/AddCard';

import api from './api';

import './styles/style.css';
import './styles/forms.css';
import './styles/modal.css';
import './styles/toast.css';

const DEFAULT_PRIMARY_COLOR = "black";
const DEFAULT_ACCENT_COLOR = "#e74c3c";

const modals = {
	"confirm": ConfirmModal,
	"invite": InviteModal,
	"add-card": AddCardModal
};

const onManage = () => window.location.href.includes("/manage/");

class App extends Component {

	state = {
		user: { id: null },
		product: { id: null },
		memberships: [],
		login: "", // Membership Id
		loading: false,
		init: false,
		modal: "",
		modalData: {}
	}

	q = { get: () => {} }
	p = () => (this.productId ? 'product=' + this.productId : '') + '&';

	componentDidMount = async () => {
		let user = { id: null }, login;
		try {
			user = JSON.parse(localStorage.getItem('USER'));
			login = localStorage.getItem('LOGIN');
		}
		catch (error) {
			console.log("Could not load user session.");
			console.log(error);
		}
		this.setState({ user, login });

		this.productId = new URLSearchParams(window.location.search).get('product');
		if (!this.productId) return this.setState({ init: true });

		// Load user and memberships synchronously
		this.loadUser();

		const err = await this.loadProduct(this.productId);
		if (err === "Invalid product ID")
            toast.error("A product with the given product ID does not exist.");
	}

	loadUser = async () => {
		// Load if on manage
		if (onManage())
			await this.loading();

		const user = await api.get('/u/users/self', {
			401: () => {
				// Unauthorized - if the user is on a manage page, send user to the login screen
				if (onManage())
					window.location.href = "/oauth/login?"+this.p();
			}
		});
		if (!user || !user._id)
			return this.loaded(); // Not logged in

		await this.loadMemberships();
	}

	loadMemberships = async () => {
		const memberships = await api.get('/u/memberships?mine=true&'+this.p());
		this.setState({ memberships });
		
		// If there are no memberships and we're on the management pages, then send user to the login page
		if (!memberships.length && onManage())
			return window.location.href = "/oauth/login?"+this.p();

		// If there is no active login, then select the first membership
		if (memberships.length && (!this.state.login || !memberships.find(m => m._id === this.state.login)))
			this.setState({ login: memberships[0]._id });

		this.loaded();
	}

	loadProduct = async (productId) => {
		const res = await api.get('/products/' + productId); // Open route
		if (!res || !res._id)
			return "Invalid product ID";

		this.setState({ product: res, init: true });
	}

	loading = () => this.setState({ loading: true });
	loaded = () => setTimeout(() => this.setState({ loading: false }), 300);
	modal = (modal="", modalData={}) => this.setState({ modal, modalData })

	getProps = (props={}) => ({
		...props,
		loading: this.loading,
		loaded: this.loaded,
		modal: this.modal,
		init: this.state.init,
		user: this.state.user,
		setUser: (user) => {
			this.setState({ user });
			// Cache locally to persist session data
			localStorage.setItem('USER', JSON.stringify(user));
		},
		memberships: this.state.memberships,
		setMemberships: (memberships) => this.setState({ memberships }),
		login: this.state.login,
		setLogin: (login) => {
			this.setState({ login });
			// Cache locally to persist session data
			localStorage.setItem('LOGIN', login);
		},
		product: this.state.product,
		loadProduct: this.loadProduct
	})

	renderModal = () => {
		if (!(this.state.modal in modals)) return <></>;
        const Modal = modals[this.state.modal];
        return <Modal
            modal={this.modal}
            data={this.state.modalData}
            loading={this.loading}
            loaded={this.loaded}
        />;
	}

	renderLoader = () => <div
		className="modal-container loader"
		style={this.state.loading ? {
			opacity: 1,
			pointerEvents: 'all',
			visibility: 'visible'
		} : {}}
	>Loading...</div>

	render = () => {
		const product = this.state.product ? this.state.product : {};
		return (<div
			style={{
				"--color-primary": product.color_primary ? product.color_primary : DEFAULT_PRIMARY_COLOR,
                "--color-accent": product.color_accent ? product.color_accent : DEFAULT_ACCENT_COLOR,
			}}
		>
			<HttpsRedirect>
				{/* App notifications */}
				<ToastContainer
					position="top-center"
					autoClose={8000}
				/>

				<Router>
					<Switch>
						{/* OAuth routes (login, createTeam, joinTeam, signup) */}
						<Route
							path="/oauth/"
							render={props => <OAuth
								{...this.getProps(props)}
							/>}
						/>

						<Route
							path="/manage/"
							render={props => <Manage
								{...this.getProps(props)}
							/>}
						/>

						{/* <Route
							default
							render={props => <Error
								{...this.getProps(props)}
							/>}
						/> */}
					</Switch>
				
				</Router>

				{this.renderModal()}
				{this.renderLoader()}
			</HttpsRedirect>
		</div>);
	}

}

export default App;
