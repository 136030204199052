// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import Modal from './Modal';
import { toast } from 'react-toastify';

import validEmail from '../../utilities/validEmail';

export default class InviteModal extends Modal {

    state = {
        email: ""
    }

    renderContent = () => {
        return (<div className="modal-content modal-center">
            
            <div style={{
                height: 160
            }} />

            <h1>Invite a new member</h1>

            <form
                className="modal-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!validEmail(this.state.email))
                        return toast.error("Email address is invalid, please check it again.");
                    if (this.props.data && this.props.data.invite)
                        this.props.data.invite(this.state.email);
                    this.props.modal();
                }}
            >
                <p>
                    Enter the new user's email address and hit <strong>Send Invitation</strong>. They will shortly receive an email with an invitation link to create their account and sign in.
                </p>

                <label>Email address</label>
                <input
                    type="text"
                    placeholder="me@abc.com"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                />
                <input
                    type="submit"
                    value="Send Invitation"
                    style={{ float: 'right' }}
                />
            </form>
            
        </div>);
    }

};