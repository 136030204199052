// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast } from 'react-toastify';

import Editable from '../../components/Editable';
import api from '../../api';

import '../../styles/profile.css';

export default class Profile extends Component {

    state = {
        avatarProcessing: false,
        password: "",
        confirm: "",
        opacity: 0
    }

    componentDidMount = () => {
        this.upload = document.createElement('input');
        this.upload.setAttribute('type', 'file');
        this.upload.setAttribute('accept', '.png,.jpg,.jpeg');
    }

    uploadError = (error) => {
        console.log("Failed to upload profile picture");
        console.log(error);
        toast.error("We weren't able to upload your profile picture.");
        this.setState({ avatarProcessing: false });
    }

    uploadAvatar = async () => {
        this.setState({ avatarProcessing: true });
        this.upload.click();
        this.upload.onchange = (e) => {
            let path;
            if (e.path)
                path = e.path;
            else if (e.composedPath && e.composedPath())
                path = e.composedPath()
            else return;

            const reader = new FileReader();
            reader.readAsDataURL(path[0].files[0]);
            reader.onload = () => {
                const image = new Image();
                image.onload = () => {
                    try {
                        // Resize image to 128px by 128px
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = 128;
                        canvas.height = 128;
                        ctx.imageSmoothingEnabled = true;
                        ctx.imageSmoothingQuality = "high";

                        if (image.width > image.height)
                            ctx.drawImage(image,
                                (1 - image.width/image.height) * 64,
                                0,
                                128*image.width/image.height,
                                128
                            );

                        else 
                            ctx.drawImage(image,
                                0,
                                (1 - image.height/image.width) * 64,
                                128,
                                128*image.height/image.width
                            );
                        
                        this.save("avatar", canvas.toDataURL());
                        this.setState({ avatarProcessing: false });
                    }
                    catch (error) {
                        this.uploadError(error);
                    }
                };
                image.src = reader.result; // Load Base64/DataURL from reader                
            }
            reader.onerror = this.uploadError;
        }
    }

    save = async (prop, value) => {
        const tmp = { ...this.props.user };
        this.props.setUser({ ...tmp, [prop]: value });
        let ERROR_HANDLED = false;
        const user = await api.patch('/u/users/self', { [prop]: value }, {
            400: (e) => {
                toast.error(e.message);
                ERROR_HANDLED = true;
            }
        });
        if (user && user._id)
            return;
        this.props.setUser(tmp);
        if (!ERROR_HANDLED)
            toast.error("Oops, something went wrong! We couldn't save your profile");
    }

    validPassword = () => {
        const password = this.state.password;
        if (password.length < 10)
            return "Password must have at least 10 characters.";
        
        if (!/\d/.test(password))
            return "Password must contain at least 1 number.";

        if (!/[@$!%*#?&]/.test(password))
            return "Password must have at least 1 special character.";

        return "ok";
    }

    render = () => {
        document.title = "My Profile | " + (this.props.product ? this.props.product.name : "Cofor.io");

        const u = this.props.user;
        if (!u) return <></>;

        const valid = this.validPassword();

        return <div className="container">
            <Scrollbars>
                <div className="scrollable">
                    <h1>My Profile</h1>
                    <div className="subtitle">Manage how others see you, or change your login email or password here.</div>
                    <hr />

                    <h2>Profile Picture</h2>
                    <div className="hint">Click on the avatar to upload a new picture.</div>

                    <div className="profile-avatar-container">
                        {this.state.avatarProcessing ? <div
                            className="profile-avatar profile-avatar-processing"
                        /> : <>
                            <img
                                className="profile-avatar"
                                style={{ opacity: this.state.opacity }}
                                src={api.apiUrl + "/users/" + u._id + "/avatar"}
                                alt="Avatar"
                                onLoad={() => this.setState({ opacity: 1 })}
                            />
                            <div className="profile-avatar profile-avatar-empty" />
                        </>}
                        <div
                            className="profile-avatar-upload"
                            onClick={this.uploadAvatar}
                        >Upload</div>
                    </div>

                    <h2>Display Name</h2>
                    <Editable
                        value={u.name}
                        setValue={(value) => this.save("name", value)}
                    />

                    <h2>Email Address</h2>
                    <Editable
                        value={u.email}
                        setValue={(value) => this.save("email", value)}
                    />

                    <h2>Login Password</h2>
                    <div className="hint">To change your password, enter the new password below.<br />Enter it once more to confirm it, and then hit the button below to save.</div>
                    <div className="profile-password">
                        <label>Password</label>
                        <input
                            type="password"
                            value={this.state.password}
                            onChange={(e) => this.setState({ password: e.target.value })}
                        />

                        {valid === "ok" ?
                            <div 
                                className="profile-password-icon profile-password-ok-icon"
                            /> :
                        this.state.password.length && valid !== "ok" ?
                        <>
                            <div className="profile-password-icon profile-password-warning-icon" />
                            <div
                                className="profile-password-warning"
                            >{valid}</div>
                        </> : ""}

                        <label>Confirm</label>
                        <input
                            type="password"
                            value={this.state.confirm}
                            onChange={(e) => this.setState({ confirm: e.target.value })}
                        />

                        {valid === "ok" && this.state.password === this.state.confirm ?
                            <div className="profile-password-icon profile-password-ok-icon" />
                        : this.state.confirm.length && this.state.password !== this.state.confirm ? <>
                            <div className="profile-password-icon profile-password-warning-icon" />
                            <div
                                className="profile-password-warning"
                            >The passwords don't match.</div>
                        </> : ""}

                        {valid === "ok" && this.state.password === this.state.confirm ? <div
                            className="button"
                            onClick={() => {
                                this.save("password", this.state.password);
                                this.setState({
                                    password: "",
                                    confirm: ""
                                });
                            }}
                        >Save Password</div> : ""}
                    </div>
                </div>
            </Scrollbars>
        </div>;
    }

};