// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import UserIcon from '../../components/UserIcon';

import '../../styles/menubar.css';

export default class Menubar extends Component {

    state = {
        listOpen: false
    }

    render = () => {
        const logo = this.props.product ? this.props.product.logo : null;
        const login = this.props.login;
        const product = this.props.product;
        const active = login ? (login.team ? login.team.name : product.name) : "";

        return <div className="menubar">
            {this.props.init ? <>
                <div
                    className="menubar-logo"
                    style={{
                        backgroundImage: "url('" + (logo ? logo : "/images/logo.png") + "')"
                    }}
                />
                {!logo && <div
                    className="menubar-title"
                    onClick={() => window.open('https://cofor.io')}
                >Cofor.io</div>}

                {/* Menu items */}
                {product ? <div className="menubar-links">
                    <div onClick={() => window.location.href = product.website}>Home</div>
                    {product.portal ? <div onClick={() => window.location.href = product.portal}>
                        Dashboard
                    </div> : ""}
                    {product.help ? <div onClick={() => window.location.href = product.help}>
                        Help Center
                    </div> : ""}
                </div> : ""}

                {/* Team memberships list: only for B2B products */}
                {product && login ? <div className="menubar-memberships">
                    <div
                        className={"menubar-memberships-active" + (this.state.listOpen ? " menubar-memberships-active-open" : "")}
                        onClick={() => this.setState({ listOpen: true })}
                    >{active}</div>

                    <div
                        className={"menubar-memberships-list" + (this.state.listOpen ? "" : " menubar-memberships-list-closed")}
                    >
                        <div
                            className="menubar-memberships-option menubar-memberships-option-selected"
                            onClick={() => this.setState({ listOpen: false })}
                        >{active}</div>
                        {this.props.memberships.map((m,idx) => {
                            const { team={ name: "" } } = m;
                            if (m._id === login._id)
                                return <React.Fragment key={idx}></React.Fragment>; // Skip active as its shown on top
                            return <div
                                key={idx}
                                className="menubar-memberships-option"
                                onClick={() => {
                                    this.props.setLogin(m._id);
                                    this.setState({ listOpen: false });
                                }}
                            >{team.name.length ? team.name : product.name}</div>
                        })}
                    </div>
                </div> : ""}
            </> : ""}

            <UserIcon
                user={this.props.user}
                product={this.props.product}
            />
        </div>;
    }

}