// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast } from 'react-toastify';

import ContextMenu from '../../components/ContextMenu';
import Loader from '../../components/Loader';
import api from '../../api';
import { getCardType } from '../../utilities/formatCard';

import '../../styles/billing.css';

export default class PaymentMethods extends Component {

    state = {
        selected: null,
        methods: []
    }

    componentDidMount = () => {
        if (this.props.login && this.props.login.team)
            this.loadMethods();
    }

    componentDidUpdate = (prev) => {
        if (this.props.login && this.props.login.team && this.props.login.team._id && (!prev.login || !prev.login.team || prev.login.team._id !== this.props.login.team._id))
            this.loadMethods();
    }

    loadMethods = async () => {
        this.setState({ selected: {}, methods: [] });
        const team = this.props.login?.team?._id;
        const methods = await api.get('/u/paymentMethods');
        const selected = await api.get(`/u/teams/${team}/payment`);
        this.setState({ selected, methods });
    }

    isDefault = (card) => {
        const sel = this.state.selected;
        if (!sel || !card) return false;
        return sel.card_type === card.card_type && sel.card_last4 === card.card_last4 && sel.card_expiry === card.card_expiry;
    }

    addCard = async (card, details) => {
        if (!this.props.login)
            return window.location.href = "/oauth/login";

        // Temporary placeholder
        const tmpId = (Math.random() + 1).toString(36).substring(2);
        const methods = [ ...this.state.methods ];
        this.setState({
            methods: [
                ...methods,
                {
                    _id: tmpId,
                    card_expiry: card.exp_month + "/" + card.exp_year,
                    card_last4: card.number.substring(card.number.length-4),
                    method_type: "card",
                    description: `${getCardType(card.number).toUpperCase()} card ending in ${card.number.substring(card.number.length-4)}`,
                    details
                }
            ]
        });

        const method = await api.post('/u/paymentMethods', {
            type: 'card',
            method: card,
            details
        });

        if (!method?._id) {
            this.setState({ methods }); // Revert!
            return toast.error("Oh, no! We weren't able to add your credit card.");
        }

        const newMethods = [ ...this.state.methods ];
        const memberIdx = newMethods.findIndex(m => m._id === tmpId);
        newMethods[memberIdx] = method;
        this.setState({ methods: newMethods });

        // Also, set the card to default
        this.setDefault(method._id);
    }

    editCard = async (card) => {

    }

    deleteCard = async (id) => {
        const methods = [ ...this.state.methods ];
        const idx = methods.findIndex(m => m._id === id);
        if (idx < 0) return;
        const newMethods = [ ...methods ];
        newMethods.splice(idx,1);
        this.setState({ methods: newMethods });
        const res = await api.delete('/u/paymentMethods/' + id);
        if (res && res.message === "OK")
            return;
        this.setState({ methods }); // Revert
        toast.error("Oops, something went wrong! We couldn't remove the credit card.");
    }

    setDefault = async (id) => {
        if (!this.props.login)
            return window.location.href = "/oauth/login";

        const team = this.props.login?.team?._id;

        // Find default card in methods
        const method = this.state.methods.find(m => m._id === id);

        // Temporary placeholder
        const selected = { ...this.state.selected };
        this.setState({ selected: null });

        const res = await api.post(`/u/teams/${team}/attach`, {
            paymentMethod: id
        });

        if (res?.message !== "OK") {
            this.setState({ selected }); // Revert!
            return toast.error("Oh, no! We were not able to set this card as the default payment method.");
        }

        this.setState({ selected: method });
    }

    renderCard = (card, active=false, editable=false, i=0) => {
        return <div
            key={i}
            className="billing-payment-card"
            style={{ backgroundImage: `url(/images/payment/${card?.card_type?.split(" ")?.[0]?.toLowerCase() ?? "none"}.png)` }}    
        >
            <div className="billing-payment-card-desc">{card?.description ?? <Loader w={220} h={32}><rect x="0" y="8" rx="4" ry="4" width="220" height="16" /></Loader>}</div>
            <div className="billing-payment-card-expiry">{card?.card_expiry ?? <Loader w={100} h={32}><rect x="20" y="8" rx="4" ry="4" width="80" height="16" /></Loader>}</div>
            <div className="billing-payment-card-last4">{card?.card_last4 ?? <Loader w={100} h={32}><rect x="40" y="8" rx="4" ry="4" width="60" height="16" /></Loader>}</div>
            <div className="billing-payment-card-active">{card ? <>
                {active ? <div className="billing-payment-card-active-default">Default</div> : ""}
            </> : <Loader w={110} h={32}><rect x="40" y="8" rx="4" ry="4" width="70" height="16" /></Loader>}</div>
            <div className="billing-payment-card-actions">
                {editable && !active ? (card ? <img
                    className="billing-payment-card-actions-button"
                    src="/images/icons/menu.png"
                    title="Actions"
                    alt="Actions"
                    onClick={(e) => this.openMenu(e, card)}
                    onContextMenu={(e) => this.openMenu(e, card)}
                /> : <Loader w={30} h={32}>
                    <circle cx="22" cy="10" r="2" />
                    <circle cx="22" cy="16" r="2" />
                    <circle cx="22" cy="22" r="2" />
                </Loader>) : ""}
            </div>
        </div>;
    }

    render = () => {
        document.title = "Payment Methods | " + (this.props.product ? this.props.product.name : "Cofor.io");
        if (!this.props.login || !this.props.product) return <></>;
        // const { team={ name: "" }} = this.props.login;

        return <div className="container">
            <Scrollbars>
                <div className="scrollable">
                    <h1>Payment Methods</h1>
                    <div className="subtitle">Manage the active payment method for this team.</div>
                    <hr />

                    <div
                        className="button billing-add-card-button"
                        onClick={() => this.props.modal("add-card", { addCard: this.addCard })}
                    >Add a new Credit Card</div>

                    <div style={{ clear: 'both' }} />

                    <div className="billing-payment-card-header">
                        <div className="billing-payment-card-title">Active Payment Method</div>
                        <div className="billing-payment-card-expiry">Expiry</div>
                        <div className="billing-payment-card-last4">Last 4</div>
                    </div>
                    {this.renderCard(this.state.selected, true)}

                    <div className="billing-payment-card-header">
                        <div className="billing-payment-card-title">My Payment Methods</div>
                        <div className="billing-payment-card-expiry">Expiry</div>
                        <div className="billing-payment-card-last4">Last 4</div>
                    </div>
                    {(this.state.methods.length ? this.state.methods : new Array(3).fill(null)).map((c,i) => this.renderCard(c, this.isDefault(c), true, i))}

                    {/* Context/actions menu */}
                    <ContextMenu
                        load={(open,close) => {
                            this.openMenu = open;
                            this.closeMenu = close;
                        }}
                        options={c => {
                            // const opts = [{
                            //     label: 'Edit details',
                            //     action: card => this.props.modal("edit-card", { card, editCard: this.editCard })
                            // }];
                            const opts = [];

                            // Set default if not already
                            if (!this.isDefault(c))
                                opts.push({
                                    label: 'Set as default',
                                    action: c => this.setDefault(c._id)
                                }, {
                                    label: 'Delete method',
                                    action: c => this.deleteCard(c._id)
                                });
                            return opts;
                        }}
                    />
                </div>
            </Scrollbars>
        </div>;
    }

};