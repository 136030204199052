// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import getAccounts from "./getAccounts";

export default (user, auth, productId) => {
    const { _id, email, name, avatar } = user;
    const account = { _id, email, name, avatar, auth };
    let accountsCache = getAccounts();
    if (!accountsCache) accountsCache = {};

    let accounts = accountsCache[productId] && accountsCache[productId].length ? accountsCache[productId] : [];

    const accountIdx = accounts.findIndex(a => a._id === _id);
    if (accountIdx >= 0)
        accounts[accountIdx] = account;
    else accounts = [ account, ...accounts ];

    // Remove any duplicates
    let deduped = [];
    for (const account of accounts) {
        const duplicate = deduped.find(a => a.email === account.email);
        if (duplicate) continue;
        deduped.push(account);
    }

    accountsCache[productId] = deduped;

    // Save to local state
    localStorage.setItem('ACCOUNTS', JSON.stringify(accountsCache));
}