// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import saveAccountLocally from '../../utilities/saveAccount';
import inputKey from '../../utilities/inputKey';
import api from '../../api';

const BASE_HEIGHT = 270;
const OFFSET_HEIGHT = 45;

export default class ResetPassword extends Component {

    state = {
        stage: "enter password",
        //  enter password
        //  link expired
        //  email sent
        password: "",
        showPassword: false
    }

    q = { get: () => {} }

    componentDidMount = async () => {
        this.q = new URLSearchParams(this.props.location.search);
        await this.props.loading();

        // Make sure the password reset link hasn't expired
        const res = await api.get('/auth/valid?userId=' + this.q.get('user') + '&otp=' + this.q.get('otp'));
        this.props.loaded();
        this.props.setHeight(BASE_HEIGHT);

        if (!res?.valid) {
            this.props.setHeight(230);
            return this.setState({ stage: "link expired" });
        }
    }

    resetPassword = async () => {
        await this.props.loading();

        const res = await api.post('/auth/reset', {
            userId: this.q.get('user'),
            otp: this.q.get('otp'),
            password: this.state.password
        });
        
        this.props.loaded();
        if (!res) {
            this.props.setHeight(230);
            return this.setState({ stage: "link expired" });
        }

        if (!res.login?.token)
            this.props.history.push('/oauth/login' + this.props.location.search);

        const auth = {
            userId: res._id,
            token: res.login.token
        };

        // Successful login...
        saveAccountLocally(res, auth, this.props.product._id);

        // Save authentication
        api.auth(auth);

        // Set user in state
        this.props.setUser(res);

        // Make user select team to login to
        this.props.history.push('/oauth/login' + this.props.location.search + '&stage=select-team');
    }

    validPassword = (password=this.state.password) => password.match(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_]).{10,})/g);

    resendPasswordResetEmail = async () => {
        await this.props.loading();
        const res = await api.post('/auth/forgot', {
            userId: this.q.get('user'),
            product: this.props.product._id,
            redirect_uri: this.q.get('redirect_uri'),
            state: this.q.get('state')
        });
        if (!res)
            return this.props.setError("We were unable to send a password reset link via email.");

        this.setState({ stage: "email sent" });
        this.props.setHeight(BASE_HEIGHT);
        this.props.loaded();
    }

    render = () => {
        document.title = "Reset your password | Cofor.io";
        return this.state.stage === "link expired" ? <>
            <h1>This link has expired</h1>
            <p style={{ marginBottom: 10 }}>Your password reset link has expired!</p>
            <p>
                Click on the button below to get a new link sent to your email.
            </p>
            <div
                className="login-button login-password-request-otp"
                onClick={this.resendPasswordResetEmail}
            >
                <strong>Click here</strong> to resend.
            </div>
        </> :
        
        this.state.stage === "email sent" ? <>
            <h1>Check your email</h1>
            <p>
                We just sent you an email with a secure link to reset your account password. Click on that link to set a new secure password for your account.
            </p>
            <div
                className="login-button login-password-request-otp"
                onClick={this.resendPasswordResetEmail}
            >
                Didn't get an email? <strong>Click here</strong> to resend.
            </div>
            <p>
                You can now close this window.
            </p>
        </> : 
        
        <>
            <h1>Reset your password</h1>
            <p>Enter a <strong>memorable</strong> password this time to reset and login to your account.</p>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!this.validPassword()) return;
                    this.resetPassword();
                }}
            >
                <label>Password</label>
                <div style={{ position: 'relative' }}>
                    <input
                        id="signup-password"
                        name="password"
                        type={this.state.showPassword ? "text" : "password"}
                        placeholder=""
                        value={this.state.password}
                        autoComplete={"off"}
                        onChange={e => {
                            const password = e.target.value;
                            this.setState({ password });
                            this.props.setHeight(BASE_HEIGHT + (this.validPassword(password) ? OFFSET_HEIGHT : 0));
                        }}
                        onKeyDown={e => inputKey(e, {
                            'Enter': (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.resetPassword();
                            }
                        })}
                    />
                    <div
                        className={"password-show-icon" + (this.state.showPassword ? " password-show-icon-hidden" : "")}
                        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                    />
                </div>
                
                {this.validPassword() ? <div className="password-strong">
                    Now that's a strong password!
                </div> : <div className="password-alert">
                    Must be at least 10 characters long and include letters, at least 1 number, and 1 special character.
                </div>}

                {this.validPassword() ? <input
                    type="submit"
                    value="Confirm"
                /> : ""}
            </form>
        </>;
    }

};