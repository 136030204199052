// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast } from 'react-toastify';

import api from '../../api';

export default class Delete extends Component {

    delete = async () => {
        await this.props.loading();
        const res = this.props.login.team ?
            await api.delete('/u/teams/' + this.props.login.team._id) : 
            await api.delete('/u/memberships/' + this.props.login._id);
        
        this.props.loaded();
        if (!res || res.message !== 'OK')
            return toast.error("Oops, something went wrong! We couldn't cancel your subscription.");
        window.location.href = this.props.product.website;
    }

    render = () => {
        if (!this.props.login || !this.props.product) return <></>;
        const product = this.props.product;
        const { team } = this.props.login;
        document.title = (team ? "Delete Team" : "Delete Account") + " | " + product.name;

        return <div className="container">
            <Scrollbars>
                <div className="scrollable">
                    <h1>Delete {team ? "Team" : "Account"}</h1>
                    <div className="subtitle">
                        {team ? <>
                            Delete your team and all associated memberships and data permanently.
                        </> : <>
                            Cancel your subscription to <strong>{product.name}</strong> and delete all associated data permanently.
                        </>}
                    </div>
                    <hr />

                    <h2>We're sorry to see you go!</h2>
                    <p>
                        {team ? <>
                            If you delete your team, accounts and data for all your team members will also be deleted permanently.<br />
                        </> : ""}
                        Click on the button below to confirm. <span style={{
                            color: 'var(--color-red)',
                            fontWeight: 500
                        }}>This cannot be reverted.</span>
                    </p>

                    <div
                        className="button manage-delete-button"
                        onClick={() => this.props.modal('confirm', {
                            message: team ? <>
                                You are about to permanently delete the <strong>{team.name}</strong> team on {product.name}. You will lose all associated memberships and team data, and this cannot be reverted!
                            </> : <>
                                You are about the cancel your subscription to <strong>{product.name}</strong>. This will delete all your data on the <strong>product.name</strong> and you will not be able to login with your account anymore.
                            </>,
                            action: this.delete
                        })}
                    >Delete {team ? "team" : "account"} permanently</div>
                </div>
            </Scrollbars>
        </div>;
    }

};