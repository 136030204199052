// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Loader from '../../components/Loader';
import capitalize from '../../utilities/capitalize';
import formatDate from '../../utilities/formatDate';
import formatCurrency from '../../utilities/formatCurrency';

import api from '../../api';

const defaultUpcoming = {
    lines:{ data: [] },
    period_start: 0
};

const defaultInvoices = {
    data: []
};

export default class Billing extends Component {

    state = {
        upcoming: defaultUpcoming,
        invoices: defaultInvoices
    }

    componentDidMount = () => {
        if (this.props.login && this.props.login.team)
            this.loadBilling();
    }

    componentDidUpdate = (prev) => {
        if (this.props.login && this.props.login.team && this.props.login.team._id && (!prev.login || !prev.login.team || prev.login.team._id !== this.props.login.team._id))
            this.loadBilling();
    }

    loadBilling = async () => {
        this.setState({ upcoming: defaultUpcoming, invoices: defaultInvoices });
        const team = this.props.login?.team?._id;
        const { upcoming=defaultUpcoming, invoices=defaultInvoices } = await api.get(`/u/teams/${team}/billing`);
        this.setState({ upcoming, invoices });
    }

    render = () => {
        document.title = "Billing & Usage | " + (this.props.product ? this.props.product.name : "Cofor.io");
        if (!this.props.login || !this.props.product) return <></>;
        const { upcoming=defaultUpcoming, invoices=defaultInvoices } = this.state;

        return <div className="container">
            <Scrollbars>
                <div className="scrollable">
                    <h1>Billing &amp; Usage</h1>
                    <div className="subtitle">See your current usage to expect on your upcoming invoice and past invoices.</div>
                    <hr />

                    <h2>Current Usage</h2>
                    
                    For billing cycle starting on {upcoming.period_start ? <>
                        {formatDate(new Date(upcoming.period_start * 1000), false, true)}
                    </> : ".."}.<br />
                    <div className="billing-usage-line-header">
                        <div className="billing-usage-line-title">Product or Service</div>
                        <div className="billing-usage-line-unit">Unit Cost</div>
                        <div className="billing-usage-line-quantity">Quantity</div>
                        <div className="billing-usage-line-total">Line Total</div>
                    </div>
                    {upcoming.lines.data.length > 0 ? upcoming.lines.data.map((line, i) => <div
                        key={i}
                        className="billing-usage-line"
                    >
                        <div className="billing-usage-line-desc">{line.description ?? ""}</div>
                        <div className="billing-usage-line-unit">{line.amount && line.quantity ? formatCurrency(line.amount / line.quantity) : "-"}</div>
                        <div className="billing-usage-line-quantity">{line.quantity?.toFixed(0) ?? "-"}</div>
                        <div className="billing-usage-line-total">{line.amount ? formatCurrency(line.amount) : "-"}</div>
                    </div>) : <div className="billing-usage-line">
                        <div className="billing-usage-line-desc"><Loader w={220} h={32}><rect x="0" y="8" rx="4" ry="4" width="220" height="16" /></Loader></div>
                        <div className="billing-usage-line-unit"><Loader w={120} h={32}><rect x="40" y="8" rx="4" ry="4" width="80" height="16" /></Loader></div>
                        <div className="billing-usage-line-quantity"><Loader w={120} h={32}><rect x="40" y="8" rx="4" ry="4" width="80" height="16" /></Loader></div>
                        <div className="billing-usage-line-total"><Loader w={120} h={32}><rect x="40" y="8" rx="4" ry="4" width="80" height="16" /></Loader></div>
                    </div>}

                    <h2>Past Invoices</h2>
                    <div className="billing-invoice-header">
                        <div className="billing-invoice-title">Invoice #</div>
                        <div className="billing-invoice-date">Date</div>
                        <div className="billing-invoice-amount">Amount</div>
                        <div className="billing-invoice-status">Status</div>
                    </div>
                    {invoices.data.length > 0 ? invoices.data.map((invoice, i) => <div
                        key={i}
                        className="billing-invoice"
                    >
                        <div className="billing-invoice-number"><a href={invoice.hosted_invoice_url} target="_blank" rel="noopener noreferrer">Invoice #{invoice.number}</a></div>
                        <div className="billing-invoice-date">{formatDate(new Date(invoice.created ?? 0))}</div>
                        <div className="billing-invoice-amount">{formatCurrency(invoice.total/100)}</div>
                        <div className="billing-invoice-status">{capitalize(invoice.status)}</div>
                    </div>) : <div className="billing-invoice">
                        <div className="billing-invoice-number"><Loader w={220} h={32}><rect x="0" y="8" rx="4" ry="4" width="220" height="16" /></Loader></div>
                        <div className="billing-invoice-date"><Loader w={120} h={32}><rect x="40" y="8" rx="4" ry="4" width="80" height="16" /></Loader></div>
                        <div className="billing-invoice-amount"><Loader w={120} h={32}><rect x="40" y="8" rx="4" ry="4" width="80" height="16" /></Loader></div>
                        <div className="billing-invoice-status"><Loader w={120} h={32}><rect x="40" y="8" rx="4" ry="4" width="80" height="16" /></Loader></div>
                    </div>}
                </div>
            </Scrollbars>
        </div>;
    }

};