// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';

export default class Loader extends Component {
    render = () => {
        return <ContentLoader
        speed={2}
        width={this.props.w}
        height={this.props.h}
        viewBox={"0 0 " + String(this.props.w) + " " + String(this.props.h)}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >{this.props.children}</ContentLoader>;
    }
}