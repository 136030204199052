// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import { getCardType } from './formatCard';

export const isValidPaymentMethod = (card, zip, country) => {

    console.log(card);

    const brand = getCardType(card.number);
    const number = card.number.length === (brand === "amex" ? 15 : 16);

    console.log(brand, number);
    
    const ok = {
        number,
        expiry: card.exp_month.length === 2 && card.exp_year.length === 4,
        cvc: card.cvc.length >= 3 && !isNaN(card.cvc),
        zip: zip.length >= 5,
        country: country.length >= 2 && country.length <= 3
    };

    ok.all = ok.number && ok.expiry && ok.cvc && ok.zip && ok.country;

    return ok;

}