const months = {
    "01" :"1 - Jan",
    "02" :"2 - Feb",
    "03" :"3 - Mar",
    "04" :"4 - Apr",
    "05" :"5 - May",
    "06" :"6 - Jun",
    "07" :"7 - Jul",
    "08" :"8 - Aug",
    "09" :"9 - Sep",
    "10" :"10 - Oct",
    "11" :"11 - Nov",
    "12" :"12 - Dec"
};

const month_keys = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 10 }, (_,i) => String(i + currentYear));

export { months, month_keys, currentYear, years };