// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import ScrollBar from 'react-custom-scrollbars';

import Gravatar from '../../components/Gravatar';
import inputKey from '../../utilities/inputKey';
import validEmail from '../../utilities/validEmail';
import countries from '../../utilities/countries';
import getLocalAccounts from '../../utilities/getAccounts';
import saveAccountLocally from '../../utilities/saveAccount';
import removeAccountLocally from '../../utilities/removeAccount';
import { isValidPaymentMethod } from '../../utilities/checkCard';
import { months, month_keys, years } from '../../utilities/addCard';
import { getDisplayCardNumber, getCardImage } from '../../utilities/formatCard';
import api from '../../api';

export default class Login extends Component {

    state = {
        // Login flow stage
        stage: "select-account",
        //  select account
        //  add account
        //  enter password
        //  perform 2FA
        //  select team
        //  reset password
        //  select payment
        //  enter payment

        // List of logged in accounts
        accounts: [],

        // List of user's payment methods
        methods: [],
        selected: null,

        // Credentials
        email: "",
        loginMethod: "password",
        loginPassword: "",
        twoFactorRequired: false,
        twoFactorMethod: "",
        twoFactorCode: "",

        // User authentication
        auth: {
            userId: "",
            token: ""
        },

        // Misc
        showPassword: false,

        // Payment method for team creation
        card: {
            number: "",
            exp_month: "",
            exp_year: "",
            cvc: ""
        },
        details: {
            address: {
                postal_code: "",
                country: "US"
            },
            email: "",
            name: ""
        },
        cardCheck: {
            number: true,
            cvc: true,
            zip: true,
            country: true,
            name: true,
            ok: false
        },
    }

    q = { get: () => {} }

    componentDidMount = () => {
        this.q = new URLSearchParams(this.props.location.search);
        if (this.q.get('stage') === 'select-team')
            return this.goToStage_selectTeam();

        if (this.props.product && this.props.product._id)
            this.goToStage_selectAccount(true);
    }

    componentDidUpdate = (prev) => {
        if ((!prev.product || !prev.product._id) && this.props.product && this.props.product._id) {
            if (this.q.get('stage') === 'select-team')
                return this.goToStage_selectTeam();

            this.goToStage_selectAccount(true);
        }
    }

    loadPaymentMethods = async () => {
        this.setState({ methods: [], selected: null });
        const methods = await api.get('/u/paymentMethods');
        this.setState({ methods });
    }

    goToStage_selectAccount = async (autoSelect=false) => {
        console.log("Stage: select account");
        await this.props.loading();
        document.title = "Login to " + this.props.product.name + " | Cofor.io";
        
        // Fetch existing product user accounts from localStorage
        const accountsCache = getLocalAccounts();
        const accounts = accountsCache && accountsCache[this.props.product._id] ? accountsCache[this.props.product._id] : [];

        // Auto-select a user account (or add if doesn't exist)
        const email = this.q.get('email');
        if (autoSelect && email) {
            const account = accounts.find(a => a.email === email);
            return this.goToStage_enterPassword(account ? account : { email });
        }

        // If there are no accounts, ask user to add a new account
        if ((!accounts || !accounts.length) && !["new-subscription", "join-team"].includes(this.q.get('next')))
            return this.goToStage_addAccount();

        const allowCreation = ["new-subscription", "join-team"].includes(this.q.get('next'));
        this.setState({ stage: "select account", accounts });
        this.props.setHeight(140 + 80*Math.min(3,accounts.length) + (allowCreation ? 80 : 0));
        this.props.loaded();
    }

    goToStage_addAccount = async (email="") => {
        console.log("Stage: sign in to new account")
        this.setState({ stage: "add account", email });
        this.props.setHeight(240 + (this.validEmail(email) ? 40 : 0));
        this.props.loaded();
        setTimeout(() => {
            const input = document.getElementById('login-email');
            if (input) input.focus();
        }, 50);
    }

    goToStage_enterPassword = async (account) => {
        console.log("Stage: enter password/otp");
        if (!account) {
            this.props.setError("Oops, something went wrong!");
            return this.goToStage_selectAccount();
        }
        await this.props.loading();

        // Set email in state
        this.setState({ email: account.email });

        // Check if local credentials are valid - get a list of teams the user is on for this product
        let auth;
        if (account && account.email && account.auth) // && account.expiry > Date.now())
            auth = account.auth;

        // Check if this user logged into another product using Cofor.io...
        else {
            const localAccounts = getLocalAccounts();
            if (localAccounts)
                for (const accounts of Object.values(localAccounts)) {
                    const userAccount = accounts.find(a => a.email === account.email);
                    if (!userAccount || !userAccount.auth) continue;
                    // We found a valid account! 
                    auth = userAccount.auth;
                    break;
                }
        }

        // We were able to find an authentication object above...
        if (auth) {
            api.auth(auth);
            const user = await api.get('/u/users/self');
            if (user) {
                this.props.setUser(user);
                saveAccountLocally(user, auth, this.props.product._id); // Rewrite with new data

                // Valid user login exists...
                // Is there a requested "next" action to handle?
                if (await this.handleNextAction() !== "none") return;

                return setImmediate(this.goToStage_selectTeam);
            }
        }

        // Else, get available login and 2FA methods for user
        const res = await api.get('/auth/check?email=' + encodeURIComponent(account.email));
        if (!res || !res.exists) {
            
            // If user is saved locally, remove from cache
            removeAccountLocally(this.props.product._id, account.email);

            this.props.setError("The email address you entered does not have any accounts associated with it.");
            return this.goToStage_addAccount(this.state.email);
        }

        this.setState({
            stage: "enter password",
            loginMethod: "password",
            loginPassword: "",
            twoFactorMethod: "app",
            twoFactorCode: ""
        });
        this.props.setHeight(300);
        this.props.loaded();

        setTimeout(() => {
            const input = document.getElementById('login-password');
            if (input) input.focus();
        }, 50);
    }

    goToStage_perform2FA = async () => {
        console.log("Stage: perform two-factor authentication")
        await this.props.loading();

        this.setState({
            stage: "perform 2fa",
            twoFactorMethod: "app",
            twoFactorCode: ""
        });
        this.props.setHeight(300);
        this.props.loaded();
    }

    goToStage_selectTeam = async () => {
        console.log("Stage: select team");
        await this.props.loading();
        
        // Load teams the user is in (memberships)
        let memberships = await api.get('/u/memberships?mine=true&product=' + this.q.get('product'));

        if (!memberships) {
            this.props.setError("Oops, something went wrong! Please try again later...");
            return this.goToStage_selectAccount();
        }

        if (!memberships.length) {
            this.props.setError("You're not a member of any team. Try a different account.");
            return this.goToStage_selectAccount();
        }

        this.props.setMemberships(memberships);

        // If there's only 1 team, then create a login for that team
        if (memberships.length === 1)
            return this.loginToMembership(memberships[0]);

        this.setState({ stage: "select team" });
        this.props.setHeight(150 + 60 * Math.min(4, memberships.length));
        this.props.loaded();
    }

    goToStage_selectPayment = async () => {
        console.log("Stage: select payment");
        await this.props.loading();
        const methods = await api.get('/u/paymentMethods');
        if (!methods) return this.goToStage_enterPayment();
        this.setState({ stage: "select payment", methods });
        this.props.setHeight(210 + 60 * Math.min(4, methods.length));
        this.props.loaded();
    }

    goToStage_enterPayment = async () => {
        console.log("Stage: enter payment");
        this.setState({ stage: "enter payment" });
        this.props.setHeight(490);
        this.props.loaded();
    }

    sendLoginOTPEmail = async () => {
        await this.props.loading();
        const res = await api.post('/auth/otp', {
            email: this.state.email,
            product: this.props.product._id
        });
        if (!res)
            return this.props.setError("Oops, something went wrong! We weren't able to send you a one-time password via email.");

        this.setState({ loginMethod: "email" });
        toast.success("We just sent you an email with your one-time password. Enter it below to log in.");
        this.props.loaded();
    }

    sendTwoFactorOTPEmail = async () => {

    }

    sendPasswordResetEmail = async () => {
        await this.props.loading();
        const res = await api.post('/auth/forgot', {
            email: this.state.email,
            product: this.props.product._id,
            redirect_uri: this.q.get('redirect_uri'),
            state: this.q.get('state')
        });
        if (!res)
            return this.props.setError("We were unable to send a password reset link via email.");

        this.setState({ stage: "reset password" });
        this.props.loaded();
    }

    ensureUserExists = async (account) => {
        await this.props.loading();

        if (!account || !account.email)
            return this.props.setError("You must enter an email address to sign in with.");

        const res = await api.post('/users/check_email', { email: account.email });
        if (!res || !res.user_exists)
            return this.props.setError("The email address you entered does not have any accounts associated with it.");

        this.goToStage_enterPassword(account);
    }

    handleNextAction = async () => {
        const card_required = this.props.product.card_required;

        // Do we have a next step specified? (URL query param 'next')
        const next = this.q.get('next');
        if (next === 'new-subscription') {
            if (card_required && !this.state.selected) return this.goToStage_selectPayment();

            // Generate a new subscription (team creation/user signup) token and send it back to the redirect_uri
            const res = await api.post(this.q.get('mode') === 'b2c' ? '/u/memberships' : '/u/teams', {
                product: this.props.product._id,
                method: this.state.selected
            });

            if (!res || !res.token)
                return this.props.redirect({}, "Failed to generate an authorization to create a new subscription on your behalf.");

            return this.props.redirect({ token: res.token });
        }

        else if (next === 'join-team') {
            // if (card_required && !this.state.selected) return this.goToStage_selectPayment();

            // Add user to the team and redirect to the product
            // Must have an invitation
            const membershipId = this.q.get('membership');
            const invitation = this.q.get('token');
            if (!membershipId || !invitation)
                return this.props.redirect({}, "Your invitation is invalid or has expired.");

            const membership = await api.post('/u/memberships/' + membershipId + '/accept', { invitation });
            if (!membership || !membership._id)
                return this.props.redirect({}, "Failed to add you to the team. Please contact a team administrator.");

            // Create a product login (select team) and login the user
            return this.loginToMembership(membership);
        }

        else return "none";
    }

    attemptUserLogin = async (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        await this.props.loading();

        let ERROR_UNAUTHORIZED = false;
        const res = await api.post('/auth/login', {
            email: this.state.email,
            method: this.state.loginMethod,
            password: this.state.loginPassword,
            remember: true // Default to 30 days
        }, {
            401: (msg) => {
                ERROR_UNAUTHORIZED = true;
                // Unauthorized - invalid login or password
                this.props.setError("Your password seems to be incorrect. Try again...");
                this.goToStage_enterPassword({ email: this.state.email });
            }
        });

        if (ERROR_UNAUTHORIZED) return; // Already handled

        if (!res || !res.login || !res.login.token) {
            this.props.setError("Oops, something went wrong! Maybe try again later...");
            return this.goToStage_selectAccount();
        }

        // Two-factor authentication required?
        if (res.tfa_required)
            return this.goToStage_perform2FA();

        const auth = {
            userId: res._id,
            token: res.login.token
        };

        // Successful login...
        saveAccountLocally(res, auth, this.props.product._id);

        // Save authentication
        api.auth(auth);

        // Set user in state
        this.props.setUser(res);

        // Is there a requested "next" action to handle?
        if (await this.handleNextAction() !== "none") return;

        // Request that user selects team
        this.goToStage_selectTeam();
    }

    loginToMembership = async (membership) => {
        await this.props.loading();

        // Login to a specific team membership and and pass the token back to the redirect URI
        const res = await api.post('/u/memberships/' + membership._id + '/login');

        if (!res || !res.token) {
            this.props.setError("Oops, something went wrong! Maybe try another account?");
            return this.goToStage_selectAccount();
        }

        // Save active login information
        this.props.setLogin(membership._id);

        // Redirect to the provided redirect URI along with the token and state
        const redirectUri = this.q.get('redirect_uri');
        if (redirectUri)
            return this.props.redirect({
                code: res.token,
                // user: encodeURIComponent(JSON.stringify(this.props.user)),
                // memberships: encodeURIComponent(JSON.stringify(this.props.memberships.map(m => ({
                //     _id: m._id,
                //     access_level: m.access_level,
                //     team: m.team ? {
                //         _id: m.team._id,
                //         name: m.team.name
                //     } : null
                // }))))
            });
        window.location.href = '/manage/profile?product=' + this.props.product._id;
    }

    validEmail = (email=this.state.email) => validEmail(email)

    validPassword = (password=this.state.loginPassword) => (password.length >= (this.state.loginMethod === " password" ? 10 : 6))

    renderMemberships = (memberships) => memberships.map((m,idx) => {
        const { team={ name: "" } } = m;
        return <div
            key={idx}
            className="login-team-container"
            title={team.name}
            onClick={() => this.loginToMembership(m)}
        >
            {team.name === "" && m.access_level === "owner" ? <>
                {this.props.product.name}
                <div className="login-team-access-level">Subscription</div>
            </> : <>
                {team.name}
                <div className="login-team-access-level">{m.access_level === "owner" ? "Owner" : m.access_level === "admin" ? "Administrator" : "Member"}</div>
            </>}
        </div>;
    });

    renderPaymentMethods = (methods) => <>
        {methods.map((card,idx) => <div
            key={idx}
            className="login-payment-container"
            style={{ backgroundImage: `url(/images/payment/${card?.card_type?.split(" ")?.[0]?.toLowerCase() ?? "none"}.png)` }}   
            title={card.description ?? ""}
            onClick={async () => {
                this.props.loading();
                await this.setState({ selected: card._id });
                this.handleNextAction();
            }}
        >{card.card_type} card *{card.card_last4}</div>)}
        <div
            className="login-payment-add-card"
            onClick={this.goToStage_enterPayment}
        >Add a new credit card</div>
    </>;

    render = () => {
        const allowCreation = ["new-subscription", "join-team"].includes(this.q.get('next'));
        return <>
            {/* Back/restart button */}
            {this.state.stage !== "select account" ? <div
                className="login-button login-back-button"
                onClick={() => this.state.stage === "enter payment" ? this.goToStage_selectPayment() : this.goToStage_selectAccount()}
            >← Back</div> : ""}

            {/* Enter user email */}
            {this.state.stage === "add account" ? <>
                <h1 className="login-box-h1-below-back">Add account</h1>
                <p>Enter the email address you use to sign in to your {this.props.product.name} account.</p>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!this.validEmail()) return;
                        this.goToStage_enterPassword({ email: this.state.email });
                    }}
                >
                    <label>Email address</label>
                    <input
                        id="login-email"
                        name="email"
                        type="text"
                        value={this.state.email}
                        autoComplete={"off"}
                        onChange={e => {
                            this.setState({ email: e.target.value });
                            this.props.setHeight(240 + (this.validEmail(e.target.value) ? 40 : 0));
                        }}
                        onKeyDown={e => inputKey(e, {
                            'Enter': (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (this.validEmail())
                                    this.goToStage_enterPassword({ email: this.state.email });
                            }
                        })}
                    />
                    {this.validEmail() ? <input
                        type="submit"
                        value="Next"
                    /> : ""}
                </form>
            </> :
            
            // Enter password/credentials
            this.state.stage === "enter password" ? <>
                <h1 className="login-box-h1-below-back">Password</h1>
                <p style={{ marginBottom: 15 }}>
                    Enter the {this.state.loginMethod === "email" ? "one-time password sent to your email" : "secret password you created"} to login to your <strong>{this.props.product.name}</strong> account.
                </p>

                <div
                    className="login-button login-password-request-otp"
                    onClick={this.sendLoginOTPEmail}
                    title="Get a one-time password (OTP) sent to your email and use that to log in."
                >Request a one-time password via email</div>

                <form style={{ position: "relative" }} onSubmit={this.attemptUserLogin}>
                    <label>Password</label>
                    <div style={{ position: 'relative' }}>
                        <input
                            id="login-password"
                            name="password"
                            type={this.state.showPassword ? "text" : "password"}
                            value={this.state.loginPassword}
                            autoComplete={"off"}
                            onChange={e => {
                                this.setState({ loginPassword: e.target.value });
                                this.props.setHeight(300 + (this.validPassword(e.target.value) ? 30 : 0));
                            }}
                            onKeyDown={e => inputKey(e, {
                                'Enter': (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.attemptUserLogin(e);
                                }
                            })}
                        />
                        <div
                            className={"password-show-icon" + (this.state.showPassword ? " password-show-icon-hidden" : "")}
                            onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                        />
                    </div>
                    {this.validPassword() ? <input
                        type="submit"
                        value={this.state.twoFactorRequired ? "Next" : "Login"}
                    /> : ""}
                    <div
                        className="login-button login-forgot-password"
                        onClick={this.sendPasswordResetEmail}
                        title="Get a link sent to your email address to reset your lost password."
                    >Forgot password?</div>
                </form>
            </> : 
            
            // Two-factor authentication
            this.state.stage === "perform 2fa" ? <>
            
            </> : 
            
            // Select team
            this.state.stage === "select team" ? <>
                <h1 className="login-box-h1-below-back">Pick a team</h1>
                <p style={{ marginBottom: 15 }}>
                    You are a member of more than one <strong>{this.props.product.name}</strong> team. Select a team to access.
                </p>
                {this.props.memberships.length < 5 ? this.renderMemberships(this.props.memberships) : <div className="login-teams-scrollbox">
                    <ScrollBar>
                        {this.renderMemberships(this.props.memberships)}
                    </ScrollBar>
                </div>}
            </> :
            
            this.state.stage === "reset password" ? <>
                <br />
                <h1>Check your email</h1>
                <p>
                    We just sent you an email with a secure link to reset your account password. Click on that link to set a new secure password for your account.
                </p>
                <div
                    className="login-button login-password-request-otp"
                    onClick={this.sendPasswordResetEmail}
                >
                    Didn't get an email? <strong>Click here</strong> to resend.
                </div>
                <p>
                    You can now close this window.
                </p>
            </> :

            this.state.stage === "select payment" ? <>
                <h1>Payment method</h1>
                <p>
                    Either pick one of your existing credit cards or add a new one.
                </p>
                {this.state.methods.length < 5 ? this.renderPaymentMethods(this.state.methods) : <div className="login-teams-scrollbox">
                    <ScrollBar>
                        {this.renderPaymentMethods(this.state.methods)}
                    </ScrollBar>
                </div>}
            </> :

            this.state.stage === "enter payment" ? <>
                <br />
                <h1>Add new credit card</h1>
                <p>
                    Enter your credit or debit card information to sign up for the platform. You will be able to change your billing method at any time.
                </p>
                <form
                    className="login-form"
                    onSubmit={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        // Valid credit card
                        const ok = isValidPaymentMethod(this.state.card, this.state.details?.address?.postal_code, this.state.details?.address?.country);
                        if (!ok.number)
                            return toast.error("You've entered an invalid credit card number. Please check again.");
                        if (!ok.cvc)
                            return toast.error("You've entered an invalid CVC (security) code. Please check again.");
                        if (!ok.expiry)
                            return toast.error("You've entered an invalid expiration date. Please check again.");
                        if (!ok.zip)
                            return toast.error("You've entered an invalid postal/ZIP code. Please check again.");

                        this.setState({ cardCheck: {
                            ...this.state.cardCheck,
                            ok: true
                        } });
                        this.props.loading();

                        // Add credit card to user account
                        const method = await api.post('/u/paymentMethods', {
                            type: 'card',
                            method: this.state.card,
                            details: this.state.details
                        });

                        if (!method?._id) {
                            this.props.loaded();
                            return toast.error("Something's wrong with your credit card. Please make sure it's valid.");
                        }

                        // Select credit card for team
                        await this.setState({ selected: method._id });
                        this.handleNextAction();
                    }}
                >
                    <label>Card holder name</label>
                    <input
                        type="text"
                        placeholder="John Doe"
                        value={this.state.details.name}
                        onChange={(e) => this.setState({ details: {
                            ...this.state.details,
                            name: e.target.value
                        } })}
                    />

                    <div className="payment-add-card-line">
                        <div className="payment-add-card-number">
                            <label>Credit card number</label>
                            <div className="input-container">
                                <input
                                    type="text"
                                    placeholder="XXXX XXXX XXXX XXXX"
                                    autoComplete='off'
                                    value={getDisplayCardNumber(this.state.card.number)}
                                    onChange={(e) => this.setState({
                                        card: {
                                            ...this.state.card,
                                            number: e.target.value.replace(/\s/g, ''),
                                        },
                                        cardCheck: {
                                            ...this.state.cardCheck,
                                            number: true
                                        }
                                    })}
                                />
                                {this.state.cardCheck.number ? <div className="payment-input-logo">{getCardImage(this.state.card.number)}</div> : ""}
                            </div>
                        </div>
                        <div className="payment-add-card-cvc">
                            <label>CVC</label>
                            <div className="input-container">
                                <input
                                    type="text"
                                    placeholder="XXX"
                                    autoComplete='off'
                                    value={this.state.card.cvc}
                                    onChange={(e) => this.setState({
                                        card: {
                                            ...this.state.card,
                                            cvc: e.target.value,
                                        },
                                        cardCheck: {
                                            ...this.state.cardCheck,
                                            cvc: true
                                        }
                                    })}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="payment-add-card-line">
                        <div className="payment-add-card-exp-mm">
                            <label>Expiry</label>
                            <Select
                                placeholder="MM"
                                className="select-container"
                                classNamePrefix="select"
                                options={month_keys.map(k => ({
                                    value: k,
                                    label: months[k]
                                }))}
                                value={this.state.card?.exp_month ? ({
                                    value: this.state.card.exp_month,
                                    label: months[this.state.card.exp_month]
                                }) : null}
                                onChange={(o) => this.setState({
                                    card: {
                                        ...this.state.card,
                                        exp_month: o.value
                                    }
                                })}
                            />
                        </div>
                        <div className="payment-add-card-exp-yyyy">
                            <label style={{ color: "transparent" }}>_</label>
                            <Select
                                placeholder="YYYY"
                                className="select-container"
                                classNamePrefix="select"
                                options={years.map(y => ({
                                    value: y,
                                    label: y
                                }))}
                                value={this.state.card?.exp_year ? ({
                                    value: this.state.card.exp_year,
                                    label: this.state.card.exp_year
                                }) : null}
                                onChange={(o) => this.setState({
                                    card: {
                                        ...this.state.card,
                                        exp_year: o.value
                                    }
                                })}
                            />
                        </div>
                        <div className="payment-add-card-zip">
                            <label>ZIP code</label>
                            <div className="input-container">
                                <input
                                    type="text"
                                    placeholder="XXXXX"
                                    autoComplete='off'
                                    value={this.state.card.postal_code}
                                    onChange={(e) => this.setState({
                                        details: {
                                            ...this.state.details,
                                            address: {
                                                ...this.state.details.address,
                                                postal_code: e.target.value
                                            }
                                        },
                                        cardCheck: {
                                            ...this.state.cardCheck,
                                            zip: true
                                        }
                                    })}
                                />
                                
                            </div>
                        </div>
                        <div className="payment-add-card-country">
                            <label>Country</label>
                            <Select
                                className="select-container"
                                classNamePrefix="select"
                                options={Object.keys(countries).map(k => ({
                                    value: k,
                                    label: countries[k]
                                }))}
                                value={this.state.details?.address?.country ? ({
                                    value: this.state.details.address.country,
                                    label: this.state.details.address.country
                                }) : null}
                                onChange={(o) => this.setState({
                                    details: {
                                        ...this.state.details,
                                        address: {
                                            ...this.state.address,
                                            country: o.value
                                        }
                                    }
                                })}
                            />
                        </div>
                    </div>

                    <div style={{ clear: 'both', height: 5 }} />

                    <input
                        type="submit"
                        value="Add Credit Card"
                    />
                </form>
            </> :

            // Default: 0 - Select account
            <>
                <h1>{this.state.accounts.length ? "Pick your account" : "Select an option"}</h1>
                {this.state.accounts.slice(0,3).map((a,idx) => <div
                    key={idx}
                    className="login-account-container"
                    onClick={() => this.goToStage_enterPassword(a)}
                >
                    <Gravatar
                        user={a}
                    />
                    <div
                        className="login-account-name"
                        title={a.name}
                    >{a.name}</div>
                    <div
                        className="login-account-email"
                        title={a.email}
                    >{a.email}</div>
                    {a.lastLogin ? <div className="login-account-last"></div> : ""}
                </div>)}

                <div
                    className="login-account-container login-account-add"
                    onClick={() => this.goToStage_addAccount()}
                >Sign in to {this.state.accounts.length ? "another" : "an existing"} account</div>

                {allowCreation ? <div
                    className="login-account-container login-account-new"
                    onClick={() => this.props.history.push('/oauth/join' + this.props.location.search)}
                >Create a new account</div> : ""}
            </>}
        </>;
    }

};