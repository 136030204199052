// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import axios from 'axios';

const local = window.location.hostname.includes("localhost");
const uiUrl = local ? "http://localhost:3000" : "https://cofor.io";
const apiUrl = local ? "http://localhost:8000" : "https://api.cofor.io";

let headers = {};
// Try to load authentication credentials from local storage
try {
    headers = JSON.parse(localStorage.getItem('AUTH'));
}
catch (error) {
    console.log("Could not load cached authentication token");
    console.log(error);
}

// Errors must be a map of status codes to callbacks that accept the error response data
const handleError = async (request, errors={}) => {
    try {
        const res = await request();
        return res.data;
    }
    catch (error) {
        if (error.response && error.response.data) {
            // Supported errors for this endpoint?
            const status = String(error.response.status);
            if (String(status) in errors)
                return errors[status](error.response.data);
            console.log(error.response.data);

        } else {
            // Invalid error object
            console.log("Invalid error object.");
            console.log(error);
        }
    }
}

const getHeaders = () => {
    if (headers && headers['X-User-Id'] && headers['X-User-Token'])
        return headers;
    return {}
};

export default {

    local,
    uiUrl,
    apiUrl,

    // Set auth headers from account
    auth: (account) => {
        headers = {
            'X-User-Id': account.userId,
            'X-User-Token': account.token
            // 'Authorization': 'Bearer ' + account.token
        };

        // Save headers to local cache
        localStorage.setItem('AUTH', JSON.stringify(headers));
    },

    get: async (endpoint, errors={}) => {
        return await handleError(async () => await axios.get(apiUrl + endpoint, { headers: getHeaders() }), errors);
    },

    post: async (endpoint, data={}, errors={}) => {
        return await handleError(async () => await axios.post(apiUrl + endpoint, data, { headers: getHeaders() }), errors);
    },

    patch: async (endpoint, data={}, errors={}) => {
        return await handleError(async () => await axios.patch(apiUrl + endpoint, data, { headers: getHeaders() }), errors);
    },

    delete: async (endpoint, errors={}) => {
        return await handleError(async () => await axios.delete(apiUrl + endpoint, { headers: getHeaders() }), errors);
    }
}