// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import '../../styles/modal.css';

export default class Modal extends React.Component {

    renderContent = () => (<></>);

    render = () => {
        return (<div className="modal-container">

            {!this.props.noClose && <div
                className="modal-back"
                onClick={() => this.props.modal()}
            >← Back</div>}

            <Scrollbars>
                {this.renderContent()}
            </Scrollbars>
            
        </div>);
    }

};