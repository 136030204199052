// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { toast } from 'react-toastify';

import ContextMenu from '../../components/ContextMenu';
import Gravatar from '../../components/Gravatar';
import Loader from '../../components/Loader';
import formatDate from '../../utilities/formatDate';

import api from '../../api';
import '../../styles/members.css';

export default class Members extends Component {

    state = {
        members: [],
        filter: ""
    }

    componentDidMount = () => {
        if (this.props.login && this.props.login.team)
            this.loadMembers();
    }

    componentDidUpdate = (prev) => {
        if (this.props.login && this.props.login.team && this.props.login.team._id && (!prev.login || !prev.login.team || prev.login.team._id !== this.props.login.team._id))
            this.loadMembers();
    }

    loadMembers = async () => {
        this.setState({ members: [] });
        const members = await api.get('/u/memberships?team=' + this.props.login.team._id);
        if (members)
            this.setState({ members });
    }

    inviteMember = async (email) => {
        if (!this.props.login)
            return window.location.href = "/oauth/login";

        // Temporary placeholder
        const tmpId = (Math.random() + 1).toString(36).substring(2);
        const members = [ ...this.state.members ];
        this.setState({
            members: [
                ...members,
                {
                    _id: tmpId,
                    access_level: "member",
                    invitation_email: email,
                    created_at: new Date()
                }
            ]
        });

        const membership = await api.post('/u/memberships', {
            team: this.props.login.team._id,
            email,
            access_level: "member"
        });

        if (!membership || !membership._id) {
            this.setState({ members }); // Revert!
            return toast.error("Oh, no! We weren't able to send an invitation email.");
        }

        const newMembers = [ ...this.state.members ];
        const memberIdx = newMembers.findIndex(m => m._id === tmpId);
        newMembers[memberIdx] = membership;
        this.setState({ members: newMembers });
    }

    editMember = async (memberId, diff={}) => {
        const members = [ ...this.state.members ];
        const idx = members.findIndex(m => m._id === memberId);
        if (idx < 0) return;
        const tmp = { ...members[idx] };
        members[idx] = { ...tmp, ...diff };
        this.setState({ members });
        const res = await api.patch('/u/memberships/' + memberId, diff);
        if (res && res._id)
            return;
        members[idx] = tmp;
        this.setState({ members });
        toast.error("Oops, something went wrong! We couldn't update the membership.");
    }

    removeMember = async (memberId) => {
        const members = [ ...this.state.members ];
        const idx = members.findIndex(m => m._id === memberId);
        if (idx < 0) return;
        const newMembers = [ ...members ];
        newMembers.splice(idx,1);
        this.setState({ members: newMembers });
        const res = await api.delete('/u/memberships/' + memberId);
        if (res && res.message === "OK")
            return;
        this.setState({ members }); // Revert
        toast.error("Oops, something went wrong! We couldn't remove the member.");
    }

    renderMember = (m,idx) => <tr key={idx}>
        <td>
            {m.user ? <>
                <Gravatar
                    className="members-line-avatar"
                    user={m.user}
                />
                <div className="members-line-name">{m.user.name}</div>
                <div className="members-line-email">{m.user.email}</div>
            </> : m.invitation_email ? <>
                <img
                    className="members-line-avatar"
                    src="/images/icons/user.png"
                    alt="New User"
                />
                <div className="members-line-name members-line-name-pending">(Pending Invitation)</div>
                <div className="members-line-email">{m.invitation_email}</div>
            </> : <Loader w={320} h={80}>
                <circle cx="24" cy="56" r="24" />
                <rect x="64" y="40" rx="4" ry="4" width="240" height="12" />
                <rect x="64" y="64" rx="4" ry="4" width="180" height="8" />
            </Loader>}
        </td>
        <td>
            {m.access_level ? <div
                className={"members-line-access members-line-access-" + m.access_level}
            >{m.access_level}</div> : <Loader w={80} h={80}><rect x="0" y="48" rx="4" ry="4" width="80" height="16" /></Loader>}
        </td>
        <td>
            {m._id ? <div className="members-line-meta">{formatDate(m.created_at)}</div> : <Loader w={100} h={80}><rect x="0" y="50" rx="4" ry="4" width="100" height="12" /></Loader>}
        </td>
        <td>
            {m._id ? <div className="members-line-meta">{m.last_login ? formatDate(m.last_login) : "-"}</div> : <Loader w={100} h={80}><rect x="0" y="50" rx="4" ry="4" width="100" height="12" /></Loader>}
        </td>
        <td>
            {m._id && m.user !== this.props.user._id && m.access_level !== 'owner' ? <img
                className="members-line-actions"
                src="/images/icons/menu.png"
                title="Actions"
                alt="Actions"
                onClick={(e) => this.openMenu(e, m)}
                onContextMenu={(e) => this.openMenu(e, m)}
            /> : <Loader w={30} h={80}>
                <circle cx="22" cy="47" r="2" />
                <circle cx="22" cy="54" r="2" />
                <circle cx="22" cy="61" r="2" />
            </Loader>}
        </td>
    </tr>

    render = () => {
        document.title = "Team Members | " + (this.props.product ? this.props.product.name : "Cofor.io");
        const filter = this.state.filter.toLowerCase();
        const members = this.state.members.filter(m => m.user ? m.user.name.toLowerCase().includes(filter) || m.user.email.toLowerCase().includes(filter) : true);

        return <div className="container">
            <Scrollbars>
                <div className="scrollable">
                    <h1>Team Members</h1>
                    <div className="subtitle">Invite new members or manage existing team members and their permissions on this team.</div>
                    <hr />

                    <div
                        className="button members-invite-button"
                        onClick={() => this.props.modal("invite", { invite: this.inviteMember })}
                    >Invite a new member</div>

                    <input
                        type="text"
                        className="members-filter"
                        value={this.state.filter}
                        onChange={(e) => this.setState({ filter: e.target.value })}
                        placeholder="Filter by name or email..."
                    />

                    <div style={{ clear: 'both', height: 40 }} />

                    <table className="members-list">
                        <thead>
                            <tr>
                                <th>Member Name & Email</th>
                                <th>Permissions</th>
                                <th>Invited On</th>
                                <th>Last Login</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {(this.state.members.length ? members : new Array(5).fill({})).map(this.renderMember)}
                        </tbody>
                    </table>

                    {/* Context/actions menu */}
                    <ContextMenu
                        load={(open,close) => {
                            this.openMenu = open;
                            this.closeMenu = close;
                        }}
                        options={m => {
                            if (!m || m.access_level === "owner" || m.user === this.props.user._id)
                                return [];

                            return [
                                {
                                    label: m => m.access_level === "admin" ? "Revoke Admin Permissions" : "Give Admin Permissions",
                                    action: m => this.editMember(m._id, {
                                        access_level: m.access_level === "admin" ? "member" : "admin"
                                    })
                                },
                                {
                                    label: "Remove Member",
                                    action: m => this.props.modal('confirm', {
                                        message: <>
                                            You are about to permanently delete {m.user ? <strong>{m.user.name}'s</strong> : "this"} membership on this team. You will lose all member data associated with them.
                                        </>,
                                        action: () => this.removeMember(m._id)
                                    })
                                }
                            ];
                        }}
                    />
                </div>
            </Scrollbars>
        </div>;
    }

};