// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import Modal from './Modal';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { getDisplayCardNumber, getCardImage } from '../../utilities/formatCard';
import { isValidPaymentMethod } from '../../utilities/checkCard';
import countries from '../../utilities/countries';
import { months, month_keys, years } from '../../utilities/addCard';

export default class AddCard extends Modal {

    state = {
        card: {
            number: "",
            exp_month: "",
            exp_year: "",
            cvc: ""
        },
        details: {
            address: {
                postal_code: "",
                country: "US"
            },
            email: "",
            name: ""
        },
        cardCheck: {
            number: true,
            cvc: true,
            zip: true,
            country: true,
            name: true
        },
    }

    renderContent = () => {
        return (<div className="modal-content modal-center">
            
            <div style={{
                height: 80
            }} />

            <h1>Add a new Credit Card</h1>

            <form
                className="modal-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    // Valid credit card
                    const ok = isValidPaymentMethod(this.state.card, this.state.details?.address?.postal_code, this.state.details?.address?.country);
                    if (!ok.number)
                        return toast.error("You've entered an invalid credit card number. Please check again.");
                    if (!ok.cvc)
                        return toast.error("You've entered an invalid CVC (security) code. Please check again.");
                    if (!ok.expiry)
                        return toast.error("You've entered an invalid expiration date. Please check again.");
                    if (!ok.zip)
                        return toast.error("You've entered an invalid postal/ZIP code. Please check again.");

                    if (this.props.data?.addCard)
                        this.props.data.addCard(this.state.card, this.state.details);
                    this.props.modal();
                }}
            >
                <label>Card holder name</label>
                <input
                    type="text"
                    placeholder="John Doe"
                    value={this.state.details.name}
                    onChange={(e) => this.setState({ details: {
                        ...this.state.details,
                        name: e.target.value
                    } })}
                />

                <div className="billing-add-card-line">
                    <div className="billing-add-card-number">
                        <label>Credit card number</label>
                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="XXXX XXXX XXXX XXXX"
                                autoComplete='off'
                                value={getDisplayCardNumber(this.state.card.number)}
                                onChange={(e) => this.setState({
                                    card: {
                                        ...this.state.card,
                                        number: e.target.value.replace(/\s/g, ''),
                                    },
                                    cardCheck: {
                                        ...this.state.cardCheck,
                                        number: true
                                    }
                                })}
                            />
                            {this.state.cardCheck.number ? <div className="billing-input-logo">{getCardImage(this.state.card.number)}</div> : ""}
                        </div>
                    </div>
                    <div className="billing-add-card-cvc">
                        <label>CVC</label>
                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="XXX"
                                autoComplete='off'
                                value={this.state.card.cvc}
                                onChange={(e) => this.setState({
                                    card: {
                                        ...this.state.card,
                                        cvc: e.target.value,
                                    },
                                    cardCheck: {
                                        ...this.state.cardCheck,
                                        cvc: true
                                    }
                                })}
                            />
                        </div>
                    </div>
                </div>

                <div className="billing-add-card-line">
                    <div className="billing-add-card-exp-mm">
                        <label>Expiry date</label>
                        <Select
                            placeholder="MM"
                            className="select-container"
                            classNamePrefix="select"
                            options={month_keys.map(k => ({
                                value: k,
                                label: months[k]
                            }))}
                            value={this.state.card?.exp_month ? ({
                                value: this.state.card.exp_month,
                                label: months[this.state.card.exp_month]
                            }) : null}
                            onChange={(o) => this.setState({
                                card: {
                                    ...this.state.card,
                                    exp_month: o.value
                                }
                            })}
                        />
                    </div>
                    <div className="billing-add-card-exp-yyyy">
                        <label style={{ color: "transparent" }}>_</label>
                        <Select
                            placeholder="YYYY"
                            className="select-container"
                            classNamePrefix="select"
                            options={years.map(y => ({
                                value: y,
                                label: y
                            }))}
                            value={this.state.card?.exp_year ? ({
                                value: this.state.card.exp_year,
                                label: this.state.card.exp_year
                            }) : null}
                            onChange={(o) => this.setState({
                                card: {
                                    ...this.state.card,
                                    exp_year: o.value
                                }
                            })}
                        />
                    </div>
                    <div className="billing-add-card-zip">
                        <label>ZIP code</label>
                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="XXXXX"
                                autoComplete='off'
                                value={this.state.card.postal_code}
                                onChange={(e) => this.setState({
                                    details: {
                                        ...this.state.details,
                                        address: {
                                            ...this.state.details.address,
                                            postal_code: e.target.value
                                        }
                                    },
                                    cardCheck: {
                                        ...this.state.cardCheck,
                                        zip: true
                                    }
                                })}
                            />
                            
                        </div>
                    </div>
                    <div className="billing-add-card-country">
                        <label>Country</label>
                        <Select
                            className="select-container"
                            classNamePrefix="select"
                            options={Object.keys(countries).map(k => ({
                                value: k,
                                label: countries[k]
                            }))}
                            value={this.state.details?.address?.country ? ({
                                value: this.state.details.address.country,
                                label: countries[this.state.details.address.country]
                            }) : null}
                            onChange={(o) => this.setState({
                                details: {
                                    ...this.state.details,
                                    address: {
                                        ...this.state.address,
                                        country: o.value
                                    }
                                }
                            })}
                        />
                    </div>
                </div>

                <div style={{ clear: 'both', height: 20 }} />

                <input
                    type="submit"
                    value="Add Credit Card"
                    style={{ float: 'right' }}
                />
            </form>
            
        </div>);
    }

};