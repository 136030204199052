// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';

import getLocalAccounts from '../../utilities/getAccounts';
import api from '../../api';

export default class Confirm extends Component {

    state = {
        membership: { team: {} }
    }

    q = { get: () => {} }

    componentDidMount = async () => {
        await this.props.loading();
        this.q = new URLSearchParams(this.props.location.search);
        document.title = "Confirm Login | Cofor.io";
        const userId = this.q.get('user');
        const productId = this.q.get('product');
        const membershipId = this.q.get('membership');
        if (!membershipId || !userId || !productId)
            return this.props.redirect({}, "You need to provide a user ID, product ID and membership ID to confirm team membership.");
        
        // Authenticate using a user access token
        let accountsCache = getLocalAccounts();
        if (!accountsCache) accountsCache = {};
        let accounts = accountsCache[productId] && accountsCache[productId].length ? accountsCache[productId] : [];

        const account = accounts.find(a => a._id === userId);

        if (!account || !account.auth)
            return this.props.history.push('/oauth/login' + this.props.location.search);

        api.auth(account.auth);
        const membership = await api.get('/u/memberships/' + membershipId);
        if (!membership)
            return this.props.redirect({}, "Your team membership is either invalid or has been deleted.");

        this.setState({ membership });
        this.props.setHeight(284);
        this.props.loaded();
    }

    confirmLogin = async () => {
        await this.props.loading();

        // Login to a specific team membership and and pass the token back to the redirect URI
        const res = await api.post('/u/memberships/' + this.state.membership._id + '/login');

        if (!res || !res.token) {
            this.props.setError("Oops, something went wrong! Maybe try another account?");
            setTimeout(() => this.props.history.push('/oauth/login' + this.props.location.href), 3000);
            return
        }

        this.props.setLogin(this.state.membership._id);

        // Redirect to the provided redirect URI along with the token and state
        this.props.redirect({ code: res.token });
    }

    render = () => {
        const m = this.state.membership;
        return <>
            <h1>Confirm Login</h1>
            <p>
                Please click the button below to confirm that you are attempting to login to <a href={this.props.product.website}>{this.props.product.name}</a> under the team listed below:
            </p>
            <div
                className="login-team-container login-team-container-confirm"
                title={m.team ? m.team.name : ""}
                onClick={() => this.confirmLogin()}
            >
                {m.team ? m.team.name : m.team_id}
                <div className="login-team-access-level">{m.access_level === "owner" ? "Owner" : m.access_level === "admin" ? "Administrator" : "Member"}</div>
            </div>

            <div
                className="button"
                onClick={this.confirmLogin}
            >Confirm</div>
        </>;
    }

};