// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { toast } from 'react-toastify';

import saveAccountLocally from '../../utilities/saveAccount';
import inputKey from '../../utilities/inputKey';
import api from '../../api';

const BASE_HEIGHT = 430;
const OFFSET_HEIGHT = 45;

export default class SignUp extends Component {

    state = {
        stage: "signup form",
        name: "",
        email: "",
        password: "",
        showPassword: false
    }

    q = { get: () => {} }

    componentDidMount = async () => {
        this.q = new URLSearchParams(this.props.location.search);
        await this.props.loading();
        const email = this.q.get('email');
        if (email) this.setState({ email });
        this.props.setHeight(BASE_HEIGHT);
        setTimeout(() => {
            this.props.loaded();
        }, 200);
    }

    createAccount = async () => {
        if (!this.validFields())
            return;

        await this.props.loading();

        const product = this.q.get('product');
        const { name, email, password } = this.state;

        // Create user account
        const membership = this.q.get('membership');
        const invitation = this.q.get('token');
        const redirectUri = this.q.get('redirect_uri');
        const stateVar = this.q.get('state');
        const res = await api.post('/users', {
            name,
            email,
            password,
            product,
            membership,
            invitation,
            redirect_uri: redirectUri,
            state: stateVar,
            b2c: this.q.get('mode') === 'b2c'
        });

        if (!res || !res._id)
            return this.props.setError("Oops, something went wrong! Please try again later...");

        // If we got here from an invitation, then the user already verified their email! Cofor.io also automatically created a membership and a login for the user. How nice is that!?
        if (res.login && res.login.token && res.membership) {
            // First add user to the account cache
            const auth = {
                userId: res._id,
                token: res.login.token
            };
            saveAccountLocally(res, auth, this.props.product._id);

            // Set user auth
            api.auth(auth);
            this.props.setUser(res);

            // Login to team
            const login = await api.post('/u/memberships/' + res.membership._id + '/login');
            if (!login || !login.token)
                return this.props.history.push('/oauth/login' + this.props.location.search);

            this.props.setLogin(membership._id);
            return this.props.redirect({ code: login.token });
        }

        this.setState({ stage: "verify email", userId: res._id });
        this.props.setHeight(285);
        this.props.loaded();
    }

    resendVerificationEmail = async () => {
        await this.props.loading();
        const { userId, email } = this.state;
        const productId = this.q.get('product');
        const redirectUri = this.q.get('redirect_uri');
        const res = await api.post('/users/' + userId + '/resend', {
            product: productId,
            redirect_uri: redirectUri,
            email
        });
        if (!res)
            return redirectUri ? this.props.redirect({}, "Unable to resend verification email.") : this.props.setError("Oops, something went wrong! Please try again later...");
        
        toast.success("Sent verification email! Follow the link sent to your email.");
        this.props.loaded();
    }

    validPassword = (password=this.state.password) => password.match(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_]).{10,})/g);

    validFields = (state=this.state) => {
        const { name, email, password } = state;
        if (!name || name.length < 4)
            return false;

        if (!email || email.length <= 4 || !email.substring(0,email.length-2).includes('@') || !email.split('@')[1].includes('.'))
            return false;

        if (!this.validPassword(password))
            return false;

        return true;
    }

    render = () => {
        document.title = "Sign up to " + this.props.product.name + " | Cofor.io";
        return this.state.stage === "verify email" ? <>
            <h1>Verify your email</h1>
            <p style={{ marginBottom: 10 }}>Welcome to <a href={this.props.product.website}>{this.props.product.name}</a>!</p>
            <p>
                We just sent you an email with a verification link. Click on that link to finish creating your account!
            </p>
            <div
                className="login-button login-password-request-otp"
                onClick={this.resendVerificationEmail}
            >
                Didn't get an email? <strong>Click here</strong> to resend.
            </div>
            <p>
                You can now close this window.
            </p>
        </> : <>
            <h1>Create an account</h1>
            <p>Enter your full name, email address, and a secret password to create an account.</p>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!this.validFields()) return;
                    this.createAccount();
                }}
            >
                <label>Full name</label>
                <input
                    id="signup-name"
                    name="name"
                    type="text"
                    placeholder="eg. Jane Doe"
                    value={this.state.name}
                    autoComplete={"off"}
                    onChange={e => {
                        const name = e.target.value;
                        this.setState({ name });
                        this.props.setHeight(BASE_HEIGHT + (this.validFields({ ...this.state, name }) ? OFFSET_HEIGHT : 0));
                    }}
                    onKeyDown={e => inputKey(e, {
                        'Enter': (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            document.getElementById('signup-email').focus();
                        }
                    })}
                />

                <label>Email</label>
                <input
                    id="signup-email"
                    name="email"
                    type="text"
                    placeholder="eg. jdoe@me.com"
                    value={this.state.email}
                    autoComplete={"off"}
                    onChange={e => {
                        const email = e.target.value;
                        this.setState({ email });
                        this.props.setHeight(BASE_HEIGHT + (this.validFields({ ...this.state, email }) ? OFFSET_HEIGHT : 0));
                    }}
                    onKeyDown={e => inputKey(e, {
                        'Enter': (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            document.getElementById('signup-password').focus();
                        }
                    })}
                />

                <label>Password</label>
                <div style={{ position: 'relative' }}>
                    <input
                        id="signup-password"
                        name="password"
                        type={this.state.showPassword ? "text" : "password"}
                        placeholder=""
                        value={this.state.password}
                        autoComplete={"off"}
                        onChange={e => {
                            const password = e.target.value;
                            this.setState({ password });
                            this.props.setHeight(BASE_HEIGHT + (this.validFields({ ...this.state, password }) ? OFFSET_HEIGHT : 0));
                        }}
                        onKeyDown={e => inputKey(e, {
                            'Enter': (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.createAccount();
                            }
                        })}
                    />
                    <div
                        className={"password-show-icon" + (this.state.showPassword ? " password-show-icon-hidden" : "")}
                        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                    />
                </div>
                
                {this.validPassword() ? <div className="password-strong">
                    Now that's a strong password!
                </div> : <div className="password-alert">
                    Must be at least 10 characters long and include letters, at least 1 number, and 1 special character.
                </div>}

                {this.validFields() ? <input
                    type="submit"
                    value="Sign up"
                /> : ""}
            </form>
        </>;
    }

};