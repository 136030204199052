// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';

import Login from './Login';
import SignUp from './SignUp';
import Create from './Create';
import Invite from './Invite';
import Confirm from './Confirm';
import VerifyEmail from './VerifyEmail';
import ResetPassword from './Reset';

import '../../styles/login.css';

export default class OAuth extends Component {

    state = {
        background: "",
        opacity: 0,
        height: 140,
        loading: false
    }

    q = { get: () => {} }

    componentDidMount = async () => {
        await this.loading();
        this.setState({
			background: "/images/background/" + String(1+Math.floor(Math.random()*8)) + ".jpg" // Pick a random background
		});

        // OAuth flow pages must include product and redirect_uri URL query parameters
        this.q = new URLSearchParams(this.props.location.search);
        const productId = this.q.get('product');
        if (!productId)
            this.redirect({}, "A valid product ID was not provided for OAuth login flow.");
    }

    loading = () => new Promise((resolve) => {
        this.setState({ loading: true });
        setTimeout(resolve, 200);
    })
	loaded = () => setTimeout(() => this.setState({ loading: false }), 200);

    setError = (error) => {
        toast.error(error);
        console.log(error);
        this.loaded();
    }

    redirect = (params={}, error=null) => setImmediate(() => {
        const redirectUri = this.q.get('redirect_uri');

        if (!redirectUri)
            return window.location.href = '/notfound?error=A redirect URI must be provided for OAuth login flow.';

        const stateVar = this.q.get('state');
        const state = stateVar ? '&state=' + stateVar : "";

        if (error)
            return window.location.href = redirectUri + '?error=' + error + state;

        let url = redirectUri + '?';
        let first = true;
        for (const [param, value] of Object.entries(params)) {
            if (!first) url += '&';
            url += param + '=' + value;
            first = false;
        }
        url += state;

        window.location.href = url;
    });

    getProps = (props) => ({
        ...props,
        loading: this.loading,
        loaded: this.loaded,
        product: this.props.product,
        user: this.props.user,
        setUser: this.props.setUser,
        login: this.props.login,
        setLogin: this.props.setLogin,
        memberships: this.props.memberships,
        setMemberships: this.props.setMemberships,
        setHeight: (height) => this.setState({ height }),
        setError: this.setError,
        redirect: this.redirect,
    })

    render = () => {
        return <div className="login-container">
            <img
                className="login-background"
                src={this.state.background}
                style={{ opacity: this.state.opacity }}
                onLoad={() => this.setState({ opacity: 1 })}
                alt=""
            />
            <div
                className="login-box"
                style={{
                    height: this.state.height,
                    marginTop: "calc(50vh - " + String(this.state.height/2 + 60) + "px)"
                }}
            >
                <div
                    className="login-loading"
                    style={{ opacity: this.state.loading ? 1 : 0 }}
                />
                <Switch>
                    {/* Login */}
                    <Route
                        path="/oauth/login" exact
                        render={(props) => <Login
                            {...this.getProps(props)}
                        />}
                    />

                    {/* Sign up */}
                    <Route
                        path="/oauth/join" exact
                        render={(props) => <SignUp
                            {...this.getProps(props)}
                        />}
                    />

                    {/* Create team */}
                    <Route
                        path="/oauth/create" exact
                        render={(props) => <Create
                            {...this.getProps(props)}
                        />}
                    />

                    {/* Join team */}
                    <Route
                        path="/oauth/invite" exact
                        render={(props) => <Invite
                            {...this.getProps(props)}
                        />}
                    />

                    {/* Confirm team */}
                    <Route
                        path="/oauth/confirm" exact
                        render={(props) => <Confirm
                            {...this.getProps(props)}
                        />}
                    />

                    {/* Verify email */}
                    <Route
                        path="/oauth/verify" exact
                        render={(props) => <VerifyEmail
                            {...this.getProps(props)}
                        />}
                    />

                    {/* Reset password */}
                    <Route
                        path="/oauth/reset" exact
                        render={(props) => <ResetPassword
                            {...this.getProps(props)}
                        />}
                    />
                </Switch>

                <div className="credits">Secured by <a className="credits-company" href="https://cofor.io" target="_blank" rel="noopener noreferrer">Cofor.io</a></div>
            </div>
        </div>;
    }

};