// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import Gravatar from './Gravatar';
// import api from '../api';

import '../styles/user.css';

export default class UserIcon extends Component {

    state = {
        menu: false
    }

    render = () => {
        const u = this.props.user ? this.props.user : {};

        if (!u || !u._id)
            return <></>;

        let initials = "";
        if (u.name)
            for (const cmp of u.name.split(" ")) {
                if (cmp.length === 0) continue;
                initials += cmp[0];
                if (initials.length === 2) break;
            }

        return <div
            className="user-icon"
            onClick={() => this.setState({ menu: !this.state.menu })}
        >
            <Gravatar
                user={u}
            />

            {/* <div className={"user-menu" + (this.state.menu ? "" : " user-menu-closed")}>
                <img
                    className="user-menu-arrow"
                    alt=""
                    src="/images/icons/arrow-menu.png"
                />

                <h1>Hi{u.name ? " " + u.name.split(" ")[0] : ""}!</h1>

                <div
                    className="a"
                    onClick={() => {
                        api.logout();
                        window.location.href = '/';
                    }}
                >Logout</div>

                <div className="credits">Secured by <a className="credits-company" href="https://cofor.io" target="_blank" rel="noopener noreferrer">Cofor.io</a></div>
            </div> */}
        </div>;
    }

}