// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { toast } from 'react-toastify';

import inputKey from '../../utilities/inputKey';
import validEmail from '../../utilities/validEmail';
import api from '../../api';
import saveAccountLocally from '../../utilities/saveAccount';

const BASE_HEIGHT = 260;
const OFFSET_HEIGHT = 45;

export default class VerifyEmail extends Component {

    state = {
        email: ""
    }

    q = { get: () => {} }

    componentDidMount = async () => {
        await this.props.loading();
        document.title = "Verify email | Cofor.io"
        this.q = new URLSearchParams(this.props.location.search);
        const productId = this.q.get('product');
        const redirectUri = this.q.get('redirect_uri');
        const userId = this.q.get('user');
        const key = this.q.get('key');

        if (!userId || !key)
            return redirectUri ? this.props.redirect({}, "A valid user ID and key was not passed to the email verification screen.") : this.props.setError("The user ID or verification key is invalid");

        const res = await api.post('/users/' + userId + '/verify', { key }, {
            409: () => {
                this.props.history.push('/oauth/login' + this.props.location.search + '&next=new-subscription');
            }
        });

        if (res && res._id && res.login && res.login.token) {
            // User verified! Save user credentials         
            const auth = {
                userId: res._id,
                token: res.login.token
            };
            saveAccountLocally(res, auth, this.props.product._id);

            // Save authentication and set user in state
            api.auth(auth);
            this.props.setUser(res);

            // For B2C mode, generate a user signup token
            // For B2B mode, generate a team creation token
            const res2 = await api.post(this.q.get('mode') === 'b2c' ? '/u/memberships' : '/u/teams', { product: productId });
            if (res2 && res2.token)
                return redirectUri ? this.props.redirect({ token: res2.token }) : this.props.history.push('/me');
                // TODO: Fix the default redirect URL
        }

        this.props.setHeight(BASE_HEIGHT);
        this.props.loaded();
    }

    resendVerificationEmail = async () => {
        await this.props.loading();
        const { email } = this.state;
        const userId = this.q.get('user');
        const productId = this.q.get('product');
        const redirectUri = this.q.get('redirect_uri');
        const res = await api.post('/users/' + userId + '/resend', {
            product: productId,
            redirect_uri: redirectUri,
            email,
            b2c: this.q.get('mode') === 'b2c'
        });
        if (!res)
            return redirectUri ? this.props.redirect({}, "Unable to resend verification email.") : this.props.setError("Oops, something went wrong! Please try again later...");

        toast.success("Sent verification email! Follow the link sent to your email.");
        this.props.loaded();
    }

    validEmail = (email=this.state.email) => validEmail(email)

    render = () => {
        return <>
            <h1>Verification failed</h1>
            <p>
                Your verification link may have expired. Enter your email address below and hit submit to get a new verification email sent to you.
            </p>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!this.validEmail()) return;
                    this.resendVerificationEmail();
                }}
            >
                <label>Email address</label>
                <input
                    id="login-email"
                    name="email"
                    type="text"
                    value={this.state.email}
                    autoComplete={"off"}
                    onChange={e => {
                        this.setState({ email: e.target.value });
                        this.props.setHeight(BASE_HEIGHT + (this.validEmail(e.target.value) ? OFFSET_HEIGHT : 0));
                    }}
                    onKeyDown={e => inputKey(e, {
                        'Enter': (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (this.validEmail())
                                this.resendVerificationEmail();
                        }
                    })}
                />
                {this.validEmail() ? <input
                    type="submit"
                    value="Submit"
                /> : ""}
            </form>
        </>;
    }

};