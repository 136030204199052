// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import api from '../../api';

export default class Invite extends Component {

    state = {
        membership: {},
        user_exists: false
    }

    q = { get: () => {} }

    componentDidMount = async () => {
        await this.props.loading();
        this.q = new URLSearchParams(this.props.location.search);
        const membershipId = this.q.get('membership');
        const invitation = this.q.get('token');
        const email = this.q.get('email');
        if (!invitation || !email)
            return this.props.redirect({}, "You don't appear to have a valid invitation code.");

        // Get membershipt/invitation data (includes team and inviter names)
        const membership = await api.get('/memberships/' + membershipId + '?token=' + invitation);
        if (!membership || !membership._id)
            return this.props.redirect({}, "Your invitation seems to have expired or been revoked!");

        // Check if user already has a Cofor.io account
        const res = await api.get('/auth/check?email=' + email);
        this.setState({
            membership,
            email,
            user_exists: res && res.exists
        });
        this.props.setHeight(335);

        this.props.loaded();
    }
    
    acceptInvitation = async () => {
        await this.props.loading();
        this.props.history.push("/oauth/" + (this.state.user_exists ? "login" : "join") + this.props.location.search + "&email=" + this.state.email + "&next=join-team");
    }

    render = () => {
        const exists = this.state.user_exists;
        const { inviter={ name: "Someone" }, team={ name: "them" } } = this.state.membership;
        const { name: product_name, website } = this.props.product ? this.props.product : {};
        const productLink = <a href={website} target="_blank" rel="noopener noreferrer">{product_name}</a>;
        const coforioLink = <a href="https://cofor.io" target="_blank" rel="noopener noreferrer">Cofor.io</a>;

        return <div className="login-invite">
            <h1>You've been invited!</h1>
            {inviter && inviter.name ? <p>
                <strong>{inviter.name.split(" ")[0]}</strong> has invited you to join <strong>{team.name}</strong> on {productLink}!
            </p> : <p>
                You have been invited to join <strong>{team.name}</strong> on {productLink}!
            </p>}
            <p>
                {productLink} uses {coforioLink} to manage user accounts and memberships.&nbsp;
                {exists ? <>
                    Looks like you already have an account on {coforioLink}. Log in to your account to accept the invitation and join the team!
                </> : <>
                    Get started by creating an account to accept the invitation to join the team!<br /><br />
                </>}
            </p>
            <div
                className="button"
                style={{ marginLeft: "auto", marginRight: "auto" }}
                onClick={this.acceptInvitation}
            >{exists ? "Sign in to account" : "Create an account"}</div>
        </div>;
    }

};