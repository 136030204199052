// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Menubar from './Menubar';
import Sidebar from './Sidebar';
import Profile from './Profile';
import Members from './Members';
import Billing from './Billing';
import PaymentMethods from './PaymentMethods';
import Delete from './Delete';

import '../../styles/manage.css';

export default class Manage extends Component {

    q = { get: () => {} }

    getProps = (props={}) => ({
        ...props,
        loading: this.props.loading,
        loaded: this.props.loaded,
        modal: this.props.modal,
        init: this.props.init,
        product: this.props.product && this.props.product._id ? this.props.product : null,
        user: this.props.user && this.props.user._id ? this.props.user : null,
        setUser: this.props.setUser,
        memberships: this.props.memberships,
        setMemberships: this.props.setMemberships,
        login: this.props.login && this.props.memberships && this.props.memberships.length ? this.props.memberships.find(m => m._id === this.props.login) : null,
        setLogin: this.props.setLogin
    })

    render = () => {
        return <>
            <Menubar
                {...this.getProps()}
            />

            <Sidebar
                {...this.getProps()}
            />

            <Switch>
                <Route
                    path="/manage/profile" exact
                    render={(props) => <Profile
                        {...this.getProps(props)}
                    />}
                />

                <Route
                    path="/manage/members" exact
                    render={(props) => <Members
                        {...this.getProps(props)}
                    />}
                />

                <Route
                    path="/manage/billing" exact
                    render={(props) => <Billing
                        {...this.getProps(props)}
                    />}
                />

                <Route
                    path="/manage/payment" exact
                    render={(props) => <PaymentMethods
                        {...this.getProps(props)}
                    />}
                />

                <Route
                    path="/manage/delete" exact
                    render={(props) => <Delete
                        {...this.getProps(props)}
                    />}
                />
            </Switch>
        </>;
    }

};